import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServiceType } from '../../constants';
import ServiceApi from '../../apis/ServiceApi';

export interface IServiceInfo {
  id: number;
  name_en: string;
  name_th: string;
  short_desc_en: string;
  short_desc_th: string;
  description_en: string;
  description_th: string;
  created_at: string;
  updated_at: string;
  product_services: any[];
  quantity: number | null;
  service_status: number;
  service_request_url: string;
  service_request_en: string;
  service_request_th: string;
  service_type: ServiceType;
  image: string;
  thumb: string;
}
export interface IServiceDetailState {
  serviceDetail: IServiceInfo | null;
  currentCustomerServiceId: number | null;
  errMess: string | null;
}
export const initialState: IServiceDetailState = {
  serviceDetail: null,
  currentCustomerServiceId: null,
  errMess: null,
};

export const fetchServiceDetail = createAsyncThunk(
  'service/fetchServiceDetail',
  async (id: string) => {
    const {
      data: { data },
    } = await ServiceApi.getServiceDetail(id);
    return data;
  }
);

export const bookServiceRequest = createAsyncThunk(
  'service/bookServiceRequest',
  async (
    { customerServiceId }: { customerServiceId: number },
    { rejectWithValue }
  ) => {
    try {
      const {
        data: {
          data: { success, data },
        },
      } = await ServiceApi.bookServiceRequest(customerServiceId);

      if (success && data?.data?.url) window.location.replace(data.data.url);

      return data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.data.message);
    }
  }
);

export const serviceDetailSlice = createSlice({
  name: 'serviceDetail',
  initialState,
  reducers: {
    setServiceDetail: (state, action: PayloadAction<IServiceInfo | null>) => {
      state.serviceDetail = action.payload;
    },
    setCurrentCustomerServiceId: (
      state,
      action: PayloadAction<number | null>
    ) => {
      state.currentCustomerServiceId = action.payload;
    },
    resetServiceDetail: (state) => {
      state.serviceDetail = initialState.serviceDetail;
    },
    resetCurrentCustomerServiceId: (state) => {
      state.currentCustomerServiceId = initialState.currentCustomerServiceId;
    },
    resetErrMess: (state) => {
      state.errMess = initialState.errMess;
    },
  },
  extraReducers: {
    [fetchServiceDetail.fulfilled as any]: (state, action) => {
      state.serviceDetail = action.payload;
    },
    [bookServiceRequest.rejected as any]: (state, action) => {
      state.errMess = action.payload;
    },
  },
});

export const {
  setServiceDetail,
  setCurrentCustomerServiceId,
  resetServiceDetail,
  resetCurrentCustomerServiceId,
  resetErrMess,
} = serviceDetailSlice.actions;

export default serviceDetailSlice.reducer;
