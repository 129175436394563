import httpClient from '../utils/axios';

const CHECKOUT_ENDPOINT_SUFFIX = '/api/checkout';

const CheckoutApi = {
  postCheckoutProduct: (
    checkoutInfo: { [key: string]: any },
    freeTrial: boolean
  ) => {
    if (freeTrial === false)
      return httpClient.post(
        `${CHECKOUT_ENDPOINT_SUFFIX}/checkout-payload`,
        checkoutInfo
      );
    else
      return httpClient.post(
        `${CHECKOUT_ENDPOINT_SUFFIX}/checkout-free`,
        checkoutInfo
      );
  },
  postPaymentStatus: (paymentInfo: { [key: string]: any }) =>
    httpClient.post(`${CHECKOUT_ENDPOINT_SUFFIX}/payment-status`, paymentInfo),
  postCheckoutMarketingConsent: (paymentInfo: { [key: string]: any }) =>
    httpClient.post(
      `${CHECKOUT_ENDPOINT_SUFFIX}/consent-marketing`,
      paymentInfo
    ),
};

export default CheckoutApi;
