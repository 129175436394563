import React from 'react';
import MuiAppBar from '@material-ui/core/AppBar';
import MuiToolbar from '@material-ui/core/Toolbar';
import styled, { withTheme } from 'styled-components';
import { RabbitLogo, CallIcon } from '../icons';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { HEADER_CALL_PHONE_NUMBER } from '../../constants';
import { useTranslation } from 'react-i18next';

const AppBar = withTheme(styled(MuiAppBar)`
  &&& {
    background: #fff;
    width: 100%;
    height: 66px;
    box-shadow: ${({ theme }) => theme.shadows[2]};
  }
`);

const Toolbar = withTheme(styled(MuiToolbar)`
  &&& {
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`);

const LogoWrapper = styled.div`
  ${({ theme }) => `
  a {
    padding: 0 12px 12px 12px;
  }

  p {
    position: absolute;
    top: 1.76rem;
    left: 2.5rem;
    width: 6.983rem;
    text-align: right;
    color: ${theme.palette.common.blackGrey};
    font-size: 0.6875rem;
  }
  `}
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 0.0625rem solid rgb(233, 237, 245);
  border-radius: 1.25rem;
  align-items: center;
  padding-inline: 0.4375rem;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  cursor: pointer;
  margin-right: 0.3125rem;
  margin-left: 0rem;
  height: auto;
`;

const PhoneWrapper = styled.div`
  ${({ theme }) => `
  margin-right: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;

  svg {
    fill: ${theme.palette.common.darkBlue}
  }
  `}
`;

const ExtWrapper = styled.div`
  ${({ theme }) => `
  display: flex;
  align-items: flex-start;
  padding-top: 0.125rem;

  span {
    color: ${theme.palette.common.darkBlue};
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 1.5rem;
  }
  `}
`;

const Header = () => {
  const { t } = useTranslation();
  return (
    <AppBar
      position="sticky"
      color="inherit"
      elevation={1}
      data-testid="header-component"
    >
      <Toolbar>
        <Grid container alignItems="center">
          <LogoWrapper>
            <IconButton component={Link} to="/" edge="start">
              <RabbitLogo />
              <Typography>{t('common.completeCare')}</Typography>
            </IconButton>
          </LogoWrapper>

          <Grid item container alignItems="center" justifyContent="flex-end" xs>
            <a
              href={`tel:${HEADER_CALL_PHONE_NUMBER}`}
              style={{ textDecoration: 'none' }}
            >
              <IconWrapper>
                <PhoneWrapper>
                  <CallIcon />
                </PhoneWrapper>
                <ExtWrapper>
                  <span>1438</span>
                </ExtWrapper>
              </IconWrapper>
            </a>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(Header);
