import React from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import '../App.css';

import PublicRoute from './PublicRoute';
import IRoute from '../models/Route';

import adminRoutes from './adminRoutes';

import appRoutes from './appRoutes';
import authRoutes from './authRoutes';
import ProtectedRoute from './ProtectedRoute';

import { Routes as ROUTES } from '../constants';

const Routes = () => {
  const protectedRoutes = [...adminRoutes, ...appRoutes];
  const publicRoutes = [...authRoutes];
  return (
    <Router>
      <Switch>
        {publicRoutes.map((route: IRoute) => (
          <PublicRoute
            key={route.path}
            component={route.component}
            layout={route.layout}
            path={route.path}
            exact
          />
        ))}
        {protectedRoutes.map((route: IRoute) => (
          <ProtectedRoute
            key={route.path}
            component={route.component}
            layout={route.layout}
            path={route.path}
            admin={route.admin}
            exact
          />
        ))}
        <Redirect to={ROUTES.AUTH_404} />
      </Switch>
    </Router>
  );
};

export default Routes;
