import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import PageApi from '../../../apis/PageApi';

export interface IPageContentDetail {
  content: string;
  content_receive: string;
  content_be_covered: string;
}

export interface IPageContent {
  id: number;
  content_en: IPageContentDetail;
  content_th: IPageContentDetail;
  type: string;
  created_at: string;
  updated_at: string;
}

export interface IPageContentState {
  pageContents: IPageContent[];
  currentPage: {
    content: IPageContent | null;
    success: boolean | null;
    messages: string[];
  };
}

const initialState: IPageContentState = {
  pageContents: [],
  currentPage: { content: null, success: null, messages: [] },
};

export const fetchPageContents = createAsyncThunk(
  'admin/thankYouPage/fetchPageContents',
  async () => {
    const { data: response } = await PageApi.getAdminPage();
    return response.data;
  }
);

export const fetchPageContentDetail = createAsyncThunk(
  'admin/thankYouPage/fetchPageContentDetail',
  async ({ pageId }: { pageId: number }) => {
    const { data: response } = await PageApi.getAdminPageDetail(pageId);
    return response.data;
  }
);

type EditPageContentDetailProps = {
  pageId: number;
  content: {
    content_en: IPageContentDetail;
    content_th: IPageContentDetail;
    type: string;
  };
};

export const editPageContentDetail = createAsyncThunk(
  'admin/thankYouPage/editPageContentDetail',
  async (
    { pageId, content }: EditPageContentDetailProps,
    { rejectWithValue }
  ) => {
    try {
      const { data: response } = await PageApi.editAdminPageDetail(
        pageId,
        content
      );
      return response.data.success;
    } catch (e: any) {
      return rejectWithValue(e.response.data.messages);
    }
  }
);

export const adminThankYouPageSlice = createSlice({
  name: 'admin/thankYouPage',
  initialState,
  reducers: {
    setPageContents: (state, action: PayloadAction<IPageContent[]>) => {
      state.pageContents = action.payload;
    },
    setCurrentPageContent: (
      state,
      action: PayloadAction<IPageContent | null>
    ) => {
      state.currentPage.content = action.payload;
    },
    resetCurrentPageContent: (state) => {
      state.currentPage = initialState.currentPage;
    },
    resetCurrentPageEditState: (state) => {
      state.currentPage.success = initialState.currentPage.success;
      state.currentPage.messages = initialState.currentPage.messages;
    },
  },
  extraReducers: {
    [fetchPageContents.fulfilled as any]: (state, action) => {
      state.pageContents = action.payload;
    },
    [fetchPageContentDetail.fulfilled as any]: (state, action) => {
      state.currentPage.content = action.payload;
    },
    [editPageContentDetail.fulfilled as any]: (state, action) => {
      state.currentPage.success = action.payload;
    },
    [editPageContentDetail.rejected as any]: (state, action) => {
      state.currentPage.messages = action.payload;
    },
  },
});

export const {
  setPageContents,
  setCurrentPageContent,
  resetCurrentPageContent,
  resetCurrentPageEditState,
} = adminThankYouPageSlice.actions;

export default adminThankYouPageSlice.reducer;
