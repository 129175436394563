import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import CustomerApi, {
  DEFAULT_PER_PAGE,
  ICustomerExportFilter,
} from '../../../apis/CustomerApi';

type NullableString = string | null;
type NullableAlphaNumeric = number | NullableString;

export interface IOrder {
  active_date: string;
  address: string;
  card_info_id: number;
  created_at: string;
  customer_id: number;
  district_code: string;
  email: string;
  expired_date: string;
  format_grand_total_price: string;
  format_sub_total_price: string;
  format_total_price: string;
  grandTotal: number;
  height: any;
  id: number;
  last_coverage_date: any;
  occupation_code: any;
  ordered_date: string;
  phone_number: number;
  plan_price_id: number;
  policy_id: any;
  postcode: string;
  product: any;
  province_code: string;
  status: string;
  subTotal: number;
  sub_district_code: string;
  sub_occupation_code: any;
  title: string;
  total: string;
  updated_at: string;
  weight: number;
  next_payment_date: string;
  last_payment_date: string;
  plan_price: any;
}

export interface ICustomer {
  id: number;
  title: string;
  first_name: string;
  last_name: string;
  email: string;
  gender: string;
  date_of_birth: string;
  nationality: string;
  occupation_th: string;
  occupation_code: string;
  occupation_en: string;
  sub_occupation_th: string;
  sub_occupation_code: string;
  sub_occupation_en: string;
  mobile_number: string;
  language: string;
  landline_number: number;
  weight: NullableAlphaNumeric;
  height: NullableAlphaNumeric;
  marital_status: any;
  card_type: number;
  ID_card_or_passport: string;
  created_at: NullableAlphaNumeric;
  updated_at: string;
  last_purchase_date: string;
  request_date_time: NullableAlphaNumeric;
  orders: IOrder[];
  address: any;
  customer_kyc: any[];
}

interface IPageOptions {
  currentPage: number;
  perPage: number;
  total: number;
}

interface IFilters {
  keyword?: string;
  purchased_product?: { name: string; value: number }[];
  last_purchase_date?: NullableString;
  create_update_date?: NullableString;
  per_page?: number;
  page: number;
}

interface ISorts {
  id?: string;
  name?: string;
  email?: string;
  gender?: string;
  date_of_birth?: string;
  nationality?: string;
  mobile_number?: string;
  last_purchase_date?: string;
  marital_status?: string;
}

export interface ICustomerState {
  list: {
    data: ICustomer[];
    pageOptions: IPageOptions;
    filters: IFilters;
    sorts: ISorts;
  };

  selected: ICustomer | null;
  errMess: NullableString;
}

const initialState: ICustomerState = {
  list: {
    data: [],
    pageOptions: {
      currentPage: 0,
      perPage: 0,
      total: 0,
    },
    filters: {
      page: 1,
      keyword: '',
      create_update_date: null,
      last_purchase_date: null,
      per_page: DEFAULT_PER_PAGE,
      purchased_product: [],
    },
    sorts: {},
  },
  selected: null,
  errMess: null,
};

export const fetchAdminCustomerList = createAsyncThunk(
  'admin/customers/fetchCustomerList',
  async (_, { getState }) => {
    const { filters, sorts } = (getState() as any).admin.customer.list;
    const payload = {
      ...filters,
      sort_field: sorts,
      purchased_product: filters.purchased_product.map(
        (product: any) => product.value
      ),
    };
    const {
      data: { data },
    } = await CustomerApi.getAdminCustomerList(payload);
    return data;
  }
);

export const exportCustomerList = createAsyncThunk(
  'admin/customers/exportCustomerList',
  async (
    { exportFilter }: { exportFilter: ICustomerExportFilter },
    { rejectWithValue }
  ) => {
    try {
      const { data: response } = await CustomerApi.exportCustomerList(
        exportFilter
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'customers.csv');
      document.body.appendChild(link);
      link.click();
    } catch (e: any) {
      return rejectWithValue(e.response.data.messages[0]);
    }
  }
);

export const adminCustomerSlice = createSlice({
  name: 'adminCustomers',
  initialState,
  reducers: {
    setSelected: (state, action: PayloadAction<ICustomer>) => {
      state.selected = action.payload;
    },
    setFilter: (state, action: PayloadAction<IFilters>) => {
      state.list.filters = action.payload;
    },
    setSorts: (state, action: PayloadAction<ISorts>) => {
      state.list.sorts = action.payload;
    },
  },
  extraReducers: {
    [fetchAdminCustomerList.fulfilled as any]: (state, action) => {
      const { data, current_page, per_page, total } = action.payload;
      state.list.data = data;
      state.list.pageOptions = {
        currentPage: Number(current_page),
        perPage: Number(per_page),
        total,
      };
    },
    [exportCustomerList.rejected as any]: (state, action) => {
      state.errMess = action.payload;
    },
  },
});

export const { setSelected, setFilter, setSorts } = adminCustomerSlice.actions;

export default adminCustomerSlice.reducer;
