import httpClient from '../utils/axios';

const PRODUCT_ENDPOINT_SUFFIX = '/api/product';
const COVERAGE_AND_CONDITION_SUFFIX = '/api/coverage-condition';

const ADMIN_PRODUCT_ENDPOINT_SUFFIX = '/api/admin/product';

const ProductApi = {
  getProductList: () => httpClient.get(PRODUCT_ENDPOINT_SUFFIX),
  getCoverageAndCondition: (productId: number, planId: number) =>
    httpClient.get(
      `${COVERAGE_AND_CONDITION_SUFFIX}/product/${productId}/plan/${planId}`
    ),
  getProductDetail: (productId: number, planId: number) =>
    httpClient.get(`${PRODUCT_ENDPOINT_SUFFIX}/${productId}/${planId}`),
  getProductDetailWithoutCustomer: (productId: number, planId: number) =>
    httpClient.get(`${PRODUCT_ENDPOINT_SUFFIX}/${productId}/${planId}/details`),
  getProductCoverages: (productId: number, planId: number) =>
    httpClient.get(
      `${PRODUCT_ENDPOINT_SUFFIX}/product-coverage/${productId}/${planId}`
    ),

  getAdminProductList: () =>
    httpClient.get(`${ADMIN_PRODUCT_ENDPOINT_SUFFIX}/list`),

  updateAdminProduct: (product: any) =>
    httpClient.post(`${ADMIN_PRODUCT_ENDPOINT_SUFFIX}/${product.id}`, product),
};

export default ProductApi;
