import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import Routes from './routes';
import themeList from './theme';
import { useAppDispatch, useAppSelector } from './hooks';
import { initI18n } from './utils/locales/i18n';
import httpClient from './utils/axios';
import { excludeRedirectUrl, Routes as ROUTES } from './constants';
import { setError } from './redux/slices/globalSlice';

function App() {
  const {
    theme,
    locale: { user, admin },
  } = useAppSelector((state) => state.global);
  const pathname = window.location.pathname;
  const isAdmin = React.useMemo(() => pathname.includes('/admin'), [pathname]);
  const dispatch = useAppDispatch();

  initI18n(isAdmin ? admin : user);

  httpClient.interceptors.response.use(
    (response) => response,
    (error) => {
      dispatch(setError(error.response.data));

      if (error.response.config.url === '/api/order/refund' && !isAdmin) {
        window.location.href = ROUTES.REFUND_ERROR;
        return;
      }

      if (!excludeRedirectUrl.includes(error.response.config.url) && !isAdmin) {
        window.location.href = ROUTES.ERROR;
      }
    }
  );

  return (
    <MuiThemeProvider theme={themeList[theme]}>
      <ThemeProvider theme={themeList[theme]}>
        <Suspense fallback={<></>}>
          <Switch>
            <Routes />
          </Switch>
        </Suspense>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

export default App;
