import React, { lazy } from 'react';
import { Routes } from '../constants';
import AppLayout from '../layouts/AppLayout';
import IRoute from '../models/Route';

const Page404 = lazy(() => import('../pages/auth/Page404'));
const AdminLogin = lazy(() => import('../pages/admin/AdminLogin'));
const ProductCoverageDetails = lazy(
  () => import('../pages/ProductCoverageDetails')
);
const TermsAndConditions = lazy(() => import('../pages/TermsAndConditions'));

const authRoutes: IRoute[] = [
  {
    id: 'HomePage',
    path: Routes.AUTH_404,
    component: Page404,
    children: null,
  },
  {
    id: 'AdminLogin',
    path: Routes.ADMIN_LOGIN,
    component: AdminLogin,
    children: null,
  },
  {
    id: 'TermsAndConditions',
    path: Routes.TERMS_AND_CONDITIONS,
    component: TermsAndConditions,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'ProductCoverageDetailsPage',
    path: `${Routes.PRODUCTS}/:productId/:planId/details`,
    component: ProductCoverageDetails,
    layout: AppLayout,
    children: null,
  },
];

export default authRoutes;
