import { OrderStatus } from '../constants';
import httpClient from '../utils/axios';

const ORDER_ENDPOINT_SUFFIX = '/api/order';
const ORDER_CANCEL_QUESTION = '/api/question/cancel-order';
const ADMIN_ORDER_ENDPOINT_SUFFIX = '/api/admin/order';

export const DEFAULT_PER_PAGE = 10;

export interface IOrderFilter {
  customer_id?: number;
  product_id?: number;
  keyword?: string;
  status?: OrderStatus;
  ordered_date?: string[];
  expired_date?: string[];
  last_payment_date?: string[];
  next_payment_date?: string[];
  per_page?: number;
  page: number;
}

export interface IOrderExportFilter {
  customer_id?: number;
  product_id?: number;
  keyword?: string;
  status?: OrderStatus;
  ordered_date?: string[];
  expired_date?: string[];
  last_payment_date?: string[];
  next_payment_date?: string[];
}

const OrderApi = {
  getOrderQuestion: (productId: number) =>
    httpClient.get(`${ORDER_CANCEL_QUESTION}/${productId}`),
  getCancelOrder: (order_id: number, last_coverage_date: any) =>
    httpClient.post(`${ORDER_ENDPOINT_SUFFIX}/cancel`, {
      order_id,
      last_coverage_date,
    }),
  getRefundAmount: (last_coverage_date: any, order_id: number) =>
    httpClient.post(`${ORDER_ENDPOINT_SUFFIX}/refund`, {
      last_coverage_date,
      order_id,
    }),

  adminGetOrders: (
    params: IOrderFilter = { page: 1, per_page: DEFAULT_PER_PAGE }
  ) => httpClient.post(`${ADMIN_ORDER_ENDPOINT_SUFFIX}/list-order`, params),

  adminCancelOrder: (order_id: number, last_coverage_date: string) =>
    httpClient.post(`${ADMIN_ORDER_ENDPOINT_SUFFIX}/cancel`, {
      order_id,
      last_coverage_date,
    }),

  adminGetOrderDetail: (orderId: number) =>
    httpClient.get(`${ADMIN_ORDER_ENDPOINT_SUFFIX}/${orderId}`),

  exportOrderList: (params: IOrderExportFilter) => {
    return httpClient.post(`${ADMIN_ORDER_ENDPOINT_SUFFIX}/export`, null, {
      params,
      responseType: 'blob',
    });
  },
};

export default OrderApi;
