import React, { lazy } from 'react';
import IRoute from '../models/Route';
import AdminLayout from '../layouts/AdminLayout';
import { Routes } from '../constants';

const AdminMainPage = lazy(() => import('../pages/admin/AdminMainPage'));
const AdminProduct = lazy(() => import('../pages/admin/AdminProducts'));
const AdminServices = lazy(() => import('../pages/admin/AdminServices'));
const AdminThankYouPage = lazy(
  () => import('../pages/admin/AdminThankYouPage')
);
const AdminCustomer = lazy(() => import('../pages/admin/AdminCustomer'));
const AdminThankYouPageDetail = lazy(
  () => import('../pages/admin/AdminThankYouPageDetail')
);
const AdminCustomerDetail = lazy(
  () => import('../pages/admin/AdminCustomerDetail')
);
const AdminProductDetail = lazy(
  () => import('../pages/admin/AdminProductDetail')
);
const AdminServiceDetail = lazy(
  () => import('../pages/admin/AdminServiceDetail')
);
const AdminOrder = lazy(() => import('../pages/admin/AdminOrder'));
const AdminOrderDetail = lazy(() => import('../pages/admin/AdminOrderDetail'));

const AdminConsentDetail = lazy(
  () => import('../pages/admin/AdminConsentDetail')
);

const adminRoutes: IRoute[] = [
  {
    id: 'AdminMainPage',
    path: Routes.ADMIN_HOMEPAGE,
    layout: AdminLayout,
    component: AdminMainPage,
    children: null,
    admin: true,
  },
  {
    id: 'AdminProduct',
    path: Routes.ADMIN_PRODUCT,
    layout: AdminLayout,
    component: AdminProduct,
    admin: true,
  },
  {
    id: 'AdminProductDetail',
    path: `${Routes.ADMIN_PRODUCT}/:id`,
    layout: AdminLayout,
    component: AdminProductDetail,
    admin: true,
  },
  {
    id: 'AdminServices',
    path: Routes.ADMIN_SERVICE,
    layout: AdminLayout,
    component: AdminServices,
    admin: true,
  },
  {
    id: 'AdminServiceDetail',
    path: `${Routes.ADMIN_SERVICE}/:id`,
    layout: AdminLayout,
    component: AdminServiceDetail,
    admin: true,
  },
  {
    id: 'AdminThankYouPage',
    path: Routes.ADMIN_THANK_YOU_PAGE,
    layout: AdminLayout,
    component: AdminThankYouPage,
    admin: true,
  },
  {
    id: 'AdminCustomerDetail',
    path: `${Routes.ADMIN_CUSTOMER}/:id`,
    layout: AdminLayout,
    component: AdminCustomerDetail,
    admin: true,
  },
  {
    id: 'AdminCustomer',
    path: Routes.ADMIN_CUSTOMER,
    layout: AdminLayout,
    component: AdminCustomer,
    admin: true,
  },
  {
    id: 'AdminThankYouPageDetail',
    path: `${Routes.ADMIN_THANK_YOU_PAGE}/:pageId`,
    layout: AdminLayout,
    component: AdminThankYouPageDetail,
    admin: true,
  },
  {
    id: 'AdminOrder',
    path: `${Routes.ADMIN_ORDER}`,
    layout: AdminLayout,
    component: AdminOrder,
    admin: true,
  },
  {
    id: 'AdminOrderDetail',
    path: `${Routes.ADMIN_ORDER}/:orderId`,
    layout: AdminLayout,
    component: AdminOrderDetail,
    admin: true,
  },
  {
    id: 'AdminConsentDetail',
    path: `${Routes.ADMIN_CONSENT}/:id`,
    layout: AdminLayout,
    component: AdminConsentDetail,
    admin: true,
  },
];

export default adminRoutes;
