import httpClient from '../utils/axios';

const QUESTION_ENDPOINT_SUFFIX = '/api/question/product';

const HealthQuestionsApi = {
  getQuestionList: (productId: number) =>
    httpClient.get(`${QUESTION_ENDPOINT_SUFFIX}/${productId}`),
};

export default HealthQuestionsApi;
