import httpClient from '../utils/axios';

const THANK_YOU_PAGE_ENDPOINT_SUFFIX = '/api/page';
const ADMIN_THANK_YOU_PAGE_ENDPOINT_SUFFIX = '/api/admin/page';

const PageApi = {
  getPage: () => httpClient.get(THANK_YOU_PAGE_ENDPOINT_SUFFIX),

  getAdminPage: () => httpClient.get(ADMIN_THANK_YOU_PAGE_ENDPOINT_SUFFIX),
  getAdminPageDetail: (pageId: number) =>
    httpClient.get(`${ADMIN_THANK_YOU_PAGE_ENDPOINT_SUFFIX}/${pageId}`),
  editAdminPageDetail: (pageId: number, content: any) =>
    httpClient.post(
      `${ADMIN_THANK_YOU_PAGE_ENDPOINT_SUFFIX}/${pageId}`,
      content
    ),
};
export default PageApi;
