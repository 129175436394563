import * as env from 'env-var';

export enum Locale {
  ENG = 'en',
  THAI = 'th',
}

export enum Duration {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum AppEnv {
  DEV = 'development',
  PROD = 'production',
}
export const DEFAULT_ENDPOINT_SUFFIX = '/api';

export enum Routes {
  HOME = '/',
  PRODUCTS = '/products',
  QUESTIONS = '/questions',
  THANK_YOU = '/thankyou',
  MY_SUBSCRIPTIONS = '/my-subscriptions',
  MY_SUBSCRIPTION_DETAIL = '/subscription',
  CHECKOUT = '/checkout',
  CHECKOUT_2C2P_REDIRECT = '/checkout-2c2p-redirect',
  CHECKOUT_RESULT = '/checkout-result',
  CHECKOUT_REJECTION = '/checkout-rejection',
  CONSENT = '/consent',
  SERVICES = '/services',
  CURRENT_COVERAGES = '/current-coverages',
  LAST_COVERAGE_DATE = '/last-coverage',
  CONFIRM_CANCELLATION = '/confirm-cancellation',
  CANCELLATION_SUCCESSFUL = '/cancellation-successful',
  CANCELLATION_REASONS = '/cancellation-reasons',
  ERROR = '/error',
  REFUND_ERROR = '/refund-error',
  AUTH_404 = '/auth/404',

  ADMIN_HOMEPAGE = '/admin',
  ADMIN_LOGIN = '/admin/login',
  ADMIN_PRODUCT = '/admin/product',
  ADMIN_SERVICE = '/admin/service',
  ADMIN_THANK_YOU_PAGE = '/admin/thankyou-page',
  ADMIN_CUSTOMER = '/admin/customer',
  ADMIN_ORDER = '/admin/order',
  ADMIN_CONSENT = '/admin/consent',

  TERMS_AND_CONDITIONS = '/terms-and-conditions',
}

export const HEADER_CALL_PHONE_NUMBER = '1438';
export const LAST_COVERAGE_THRESHOLD = 15;

export enum Products {
  MASS_OPP = 1,
  HEALTH_MORE = 2,
  HIP = 3,
  CANCER = 4,
}

export enum ProductType {
  REAL = 'real',
  VIRTUAL = 'virtual',
}

export enum ServiceType {
  DoctorConsultation = 'BOOK',
  NursingHome = 'CALL',
  EmergencyService = 'REQUEST',
}

export enum ConsentType {
  QUESTION = 0,
  CONSENT = 1,
  CONSENT_MARKETING = 2,
}

export enum ReasonOptionValues {
  OTHER = 'Others',
  USE_OTHER_SERVICE = 'useOtherService',
}

export enum OrderStatus {
  PROCESSING = 'processing',
  CANCELLED = 'cancelled',
  PENDING = 'pending',
  CONFIRMED = 'confirmed',
  REFUNDED = 'refunded',
  EXPIRE = 'expire',
  FAILED = 'failed',
  REJECTED = 'rejected',
  PENDING_CANCELLATION = 'pending_cancellation',
  PENDING_CANCELLATION_REFUND = 'pending_cancellation_refund',
}

export const excludeRedirectUrl = [
  '/api/checkout/checkout-payload',
  '/api/checkout/checkout-free',
  '/api/rabbit/sync-rr-member',
  '/api/rabbit/check-rr-member',
  '/api/admin/auth/token',
];

export const GTM_CONTAINER_ID = 'GTM-K2DJLCZ';
export const GA_MEASUREMENT_ID = 'UA-40609749-80';
export const NODE_ENV = env.get('NODE_ENV').asString();
export const IMGIX_DOMAIN = env.get('REACT_APP_IMGIX_DOMAIN').asString();
export const CCPP_API = env.get('REACT_APP_2C2P_API').asString();
export const NEW_RELIC_ACC_ID = env
  .get('REACT_APP_NEW_RELIC_ACCOUNT_ID')
  .asString();
export const NEW_RELIC_LIC_KEY = env
  .get('REACT_APP_NEW_RELIC_LICENSE_KEY')
  .asString();
export const NEW_RELIC_APP_ID = env
  .get('REACT_APP_NEW_RELIC_APPLICATION_ID')
  .asString();
export const DEFAULT_LOCALE = env.get('REACT_APP_DEFAULT_LOCALE').asString();
export const API_ENDPOINT = env.get('REACT_APP_API_ENDPOINT').asString();
export const CCPP_API_ENDPOINT = env
  .get('REACT_APP_PAYMENT_2C2P_API_ENDPOINT')
  .asString();

export const U2CareProductId = 1;
