import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import consentApi from '../../../apis/ConsentApi';

interface IConsentDetails {
  type: string;
  title: string;
  content?: string;
  warningText?: string;
}

interface IConsentInfo {
  productId: number;
  consentDetails: {
    en: IConsentDetails[];
    th: IConsentDetails[];
  };
}

export interface IConsentState {
  consent: IConsentInfo | null;
}

const initialState: IConsentState = {
  consent: null,
};

export const fetchConsentsDetail = createAsyncThunk(
  'admin/consents/fetchConsentsDetail',
  async (id: number) => {
    const {
      data: { data },
    } = await consentApi.getAdminConsentDetail(id);
    return data;
  }
);

export const updateAdminConsent = createAsyncThunk(
  'admin/consent/updateAdminConsent',
  async (consent: any) => {
    const { data } = await consentApi.updateConsent(consent);
    return data;
  }
);

export const adminConsentSlice = createSlice({
  name: 'consent',
  initialState,
  reducers: {
    setConsent: (state, action: PayloadAction<IConsentInfo>) => {
      state.consent = action.payload;
    },
    resetConsent: (state) => {
      state.consent = initialState.consent;
    },
  },
  extraReducers: {
    [fetchConsentsDetail.fulfilled as any]: (state, action) => {
      state.consent = action.payload;
    },
  },
});

export const { setConsent, resetConsent } = adminConsentSlice.actions;

export default adminConsentSlice.reducer;
