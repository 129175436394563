import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ProductApi from '../../../apis/ProductApi';

export interface IAdminProduct {
  id: number;
  name_en: string;
  name_th: string;
  short_desc_en: string;
  short_desc_th: string;
  description_en: string;
  description_th: string;
  image: string;
  status: number;
  created_at: string;
  updated_at: string;
}

export interface IAdminProductsState {
  products: IAdminProduct[];
  selected: IAdminProduct | null;
}

const initialState: IAdminProductsState = {
  products: [],
  selected: null,
};

export const fetchAdminProducts = createAsyncThunk(
  'admin/products/fetchAdminProducts',
  async () => {
    const {
      data: { data },
    } = await ProductApi.getAdminProductList();
    return data;
  }
);

export const updateAdminProduct = createAsyncThunk(
  'admin/products/updateAdminProduct',
  async (product: any, { rejectWithValue }) => {
    try {
      const { data } = await ProductApi.updateAdminProduct(product);
      return data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const adminProductsSlice = createSlice({
  name: 'adminProducts',
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
  },
  extraReducers: {
    [fetchAdminProducts.fulfilled as any]: (state, action) => {
      state.products = action.payload;
    },
  },
});

export const { setSelected } = adminProductsSlice.actions;

export default adminProductsSlice.reducer;
