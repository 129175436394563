import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import OrderApi from '../../apis/OrderApi';
import { CancelOrderParams } from '../../utils/types';

interface ISubQuestion {
  id: number;
  product_id: number;
  type: string;
  question_code: string | null;

  content_en: string;
  content_th: string;
  parent_id: number;
  created_at: string | null;
  updated_at: string | null;
  options: any;
}

interface IOption {
  id: number;
  question_id: number;
  content_en: { label: string; value: string };
  content_th: { label: string; value: string };
  is_correct: number;
  created_at: string | null;
  updated_at: string | null;
  sub_question: ISubQuestion[];
}

interface ICancelQuestion {
  id: number;
  product_id: number;
  type: string;
  content_en: string;
  content_th: string;
  created_at: string | null;
  updated_at: string | null;
  options: IOption[];
}

interface IOrderRefund {
  refund_amount: any;
}

interface ICancelledProduct {
  id: number;
  customer_id: number;
  plan_price_id: number;
  card_info_id: number;
  subTotal: number;
  total: any;
  grandTotal: any;
  status: string;
  ordered_date: string;
  active_date: string;
  expired_date: string;
  last_coverage_date: string;
  last_payment_date: string;
  title: string;
  phone_number: number;
  email: string;
  address: string;
  province_code: string;
  district_code: string;
  sub_district_code: string;
  postcode: string;
  weight: string;
  height: string;
  occupation_code: string;
  sub_occupation_code: string;
  created_at: string;
  updated_at: string;
  product_name_en: string;
  product_name_th: string;
  duration: string;
}

export interface ICancelState {
  cancelQuestion: ICancelQuestion[];
  orderRefund: IOrderRefund;
  cancelledProduct: ICancelledProduct | null;
  last_coverage_date: any;
  errMess: string | null;
}

const initialState: ICancelState = {
  cancelQuestion: [],
  orderRefund: { refund_amount: 0 },
  cancelledProduct: null,
  last_coverage_date: '',
  errMess: null,
};

export const fetchOrderQuestion = createAsyncThunk(
  'order/fetchOrderQuestion',
  async (productId: number) => {
    const {
      data: { data },
    } = await OrderApi.getOrderQuestion(productId);
    return data;
  }
);

export const fetchOrderRefund = createAsyncThunk(
  'order/fetchOrderRefund',
  async (
    { last_coverage_date, order_id }: CancelOrderParams,
    { rejectWithValue }
  ) => {
    try {
      const {
        data: { data },
      } = await OrderApi.getRefundAmount(last_coverage_date, order_id);
      return data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const fetchOrderCancel = createAsyncThunk(
  'order/fetchOrderCancel',
  async (
    { order_id, last_coverage_date }: CancelOrderParams,
    { rejectWithValue }
  ) => {
    try {
      const {
        data: { data },
      } = await OrderApi.getCancelOrder(order_id, last_coverage_date);
      return data.order;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderQuestion: (state, action: PayloadAction<ICancelQuestion[]>) => {
      state.cancelQuestion = action.payload;
    },
    setOrderRefund: (state, action: PayloadAction<IOrderRefund>) => {
      state.orderRefund = action.payload;
    },
    getCancelInfo: (state, action: PayloadAction<ICancelledProduct>) => {
      state.cancelledProduct = action.payload;
    },
    setLastCoverageDate: (state, action: PayloadAction<string>) => {
      state.last_coverage_date = action.payload;
    },
    resetErrMess: (state) => {
      state.errMess = initialState.errMess;
    },
  },
  extraReducers: {
    [fetchOrderQuestion.fulfilled as any]: (state, action) => {
      state.cancelQuestion = action.payload;
    },
    [fetchOrderRefund.fulfilled as any]: (state, action) => {
      state.orderRefund = action.payload;
    },
    [fetchOrderRefund.rejected as any]: (state, action) => {
      state.errMess = action.payload;
    },
    [fetchOrderCancel.fulfilled as any]: (state, action) => {
      state.cancelledProduct = action.payload;
    },
    [fetchOrderCancel.rejected as any]: (state, action) => {
      state.errMess = action.payload;
    },
  },
});

export const {
  setOrderQuestion,
  setOrderRefund,
  getCancelInfo,
  setLastCoverageDate,
  resetErrMess,
} = orderSlice.actions;

export default orderSlice.reducer;
