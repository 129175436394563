import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import 'react-dates/initialize';
import { withErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './pages/Error';
import { NewRelicManager } from './components/NewRelic';
import { AppEnv, NODE_ENV, GTM_CONTAINER_ID } from './constants';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: GTM_CONTAINER_ID,
};

if (NODE_ENV !== AppEnv.DEV) {
  NewRelicManager.initialize();
  TagManager.initialize(tagManagerArgs);
}

const AppWithErrorBoundary = withErrorBoundary(App, {
  FallbackComponent: ErrorFallback,
});

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <PersistGate loading={null} persistor={persistor}>
        <AppWithErrorBoundary />
      </PersistGate>
    </Router>
  </Provider>,
  document.getElementById('root')
);
