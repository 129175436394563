import React, { lazy } from 'react';
import IRoute from '../models/Route';
import AppLayout from '../layouts/AppLayout';
import { Routes } from '../constants';

const HomePage = lazy(() => import('../pages/Home'));
const ProductDetailPage = lazy(() => import('../pages/ProductDetail'));
const HealthQuestions = lazy(() => import('../pages/HealthQuestions'));
const ProductConsent = lazy(() => import('../pages/ProductConsent'));
const ThankYouPage = lazy(() => import('../pages/ThankYou'));
const CancelledProductPage = lazy(() => import('../pages/CancelledProduct'));
const PDPAConsentPage = lazy(() => import('../pages/PDPAConsent'));
const MySubscriptionsPage = lazy(() => import('../pages/MySubscriptions'));
const MySubscriptionDetailPage = lazy(
  () => import('../pages/MySubscriptionDetail')
);
const CustomerCheckout = lazy(() => import('../pages/CustomerCheckout'));
const CustomerCheckoutRedirect = lazy(
  () => import('../pages/CustomerCheckoutRedirect')
);
const CustomerCheckoutResult = lazy(
  () => import('../pages/CustomerCheckoutResult')
);
const CheckoutRejection = lazy(() => import('../pages/CheckoutRejection'));
const ServiceDetailPage = lazy(() => import('../pages/ServiceDetail'));
const CurrentCoverages = lazy(() => import('../pages/CurrentCoverages'));
const LastCoverageDatePage = lazy(() => import('../pages/LastCoverageDate'));
const ConfirmCancellation = lazy(() => import('../pages/ConfirmCancellation'));
const CancellationSuccessful = lazy(
  () => import('../pages/CancellationSuccessful')
);
const CancellationReasons = lazy(() => import('../pages/CancellationReasons'));
const ErrorPage = lazy(() => import('../pages/Error'));
const RefundErrorPage = lazy(() => import('../pages/Error/RefundError'));

const appRoutes: IRoute[] = [
  {
    id: 'HomePage',
    path: '/',
    component: HomePage,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'ProductDetailPage',
    path: `${Routes.PRODUCTS}/:productId/:planId/:position`,
    component: ProductDetailPage,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'HealthQuestions',
    path: `${Routes.QUESTIONS}/:productId/:planId/:position`,
    component: HealthQuestions,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'ProductConsent',
    path: Routes.CONSENT,
    component: ProductConsent,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'ThankYouPage',
    path: `${Routes.THANK_YOU}/:productId/:planId`,
    component: ThankYouPage,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'CancelledProductPage',
    path: '/cancelled-product/:id',
    component: CancelledProductPage,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'MySubscriptionsPage',
    path: Routes.MY_SUBSCRIPTIONS,
    component: MySubscriptionsPage,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'MySubscriptionDetailPage',
    path: Routes.MY_SUBSCRIPTION_DETAIL,
    component: MySubscriptionDetailPage,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'PDPAConsentPage',
    path: '/:serviceId/pdpa-consent',
    component: PDPAConsentPage,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'CustomerCheckout',
    path: `${Routes.CHECKOUT}/:position`,
    component: CustomerCheckout,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'CustomerCheckoutRedirect',
    path: Routes.CHECKOUT_2C2P_REDIRECT,
    component: CustomerCheckoutRedirect,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'CustomerCheckoutResult',
    path: Routes.CHECKOUT_RESULT,
    component: CustomerCheckoutResult,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'CheckoutRejection',
    path: `${Routes.CHECKOUT_REJECTION}`,
    component: CheckoutRejection,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'ServiceDetailPage',
    path: `${Routes.SERVICES}/:serviceId`,
    component: ServiceDetailPage,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'CurrentCoverages',
    path: Routes.CURRENT_COVERAGES,
    component: CurrentCoverages,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'LastCoverageDatePage',
    path: Routes.LAST_COVERAGE_DATE,
    component: LastCoverageDatePage,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'ConfirmCancellationPage',
    path: Routes.CONFIRM_CANCELLATION,
    component: ConfirmCancellation,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'CancellationSuccessfulPage',
    path: Routes.CANCELLATION_SUCCESSFUL,
    component: CancellationSuccessful,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'CancellationReasonsPage',
    path: Routes.CANCELLATION_REASONS,
    component: CancellationReasons,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'ErrorPage',
    path: Routes.ERROR,
    component: ErrorPage,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'RefundErrorPage',
    path: Routes.REFUND_ERROR,
    component: RefundErrorPage,
    layout: AppLayout,
    children: null,
  },
];

export default appRoutes;
