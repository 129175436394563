import { DEFAULT_ENDPOINT_SUFFIX } from '../constants';
import httpClient from '../utils/axios';

const CUSTOMER_ENDPOINT_SUFFIX = '/api/customer';

const AuthApi = {
  getCustomerInfo: () => httpClient.get(CUSTOMER_ENDPOINT_SUFFIX),
  checkNewCustomer: () =>
    httpClient.get(`${DEFAULT_ENDPOINT_SUFFIX}/subscription/ordered`),
};

export default AuthApi;
