import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import SubscriptionApi from '../../apis/SubscriptionApi';

export interface SubscribedProduct {
  order_id: number;
  product_name_en: string;
  product_name_th: string;
  total: number;
  active_date: string;
  expired_date: string;
  next_payment: string;
  last_payment: string;
  status: string;
}

export interface ISubscriptionState {
  subscribedProducts: SubscribedProduct[];
  currentOrder?: { orderId?: number; productId?: number; planId?: number };
}

const initialState: ISubscriptionState = {
  subscribedProducts: [],
};

export const getSubscriptionInfo = createAsyncThunk(
  'subscription/getSubscriptionInfo',
  async () => {
    const { data: response } = await SubscriptionApi.getSubscriptionList();
    return response.data;
  }
);

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscribedProducts: (
      state,
      action: PayloadAction<SubscribedProduct[]>
    ) => {
      state.subscribedProducts = action.payload;
    },
    setCurrentOrder: (state, action) => {
      state.currentOrder = action.payload;
    },
  },
  extraReducers: {
    [getSubscriptionInfo.fulfilled as any]: (state, action) => {
      state.subscribedProducts = action.payload;
    },
  },
});

export const { setSubscribedProducts, setCurrentOrder } =
  subscriptionSlice.actions;

export default subscriptionSlice.reducer;
