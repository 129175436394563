import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import pageApi from '../../apis/PageApi';

export interface IPageContent {
  content: string;
  content_receive: string;
  content_be_covered: string;
}

export interface IPage {
  id: number;
  content_en: IPageContent;
  content_th: IPageContent;
  type: string;
}

export interface IPageState {
  pages: IPage[];
}

const initialState: IPageState = {
  pages: [],
};

export const fetchPages = createAsyncThunk('pages/fetchPages', async () => {
  const { data: response } = await pageApi.getPage();
  return response.data;
});

export const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    setPages: (state, action: PayloadAction<IPage[]>) => {
      state.pages = action.payload;
    },
  },
  extraReducers: {
    [fetchPages.fulfilled as any]: (state, action) => {
      state.pages = action.payload;
    },
  },
});

export const { setPages } = pagesSlice.actions;

export default pagesSlice.reducer;
