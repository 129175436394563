import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import AnswerApi from '../../apis/AnswerApi';
import QuestionService from '../../apis/HealthQuestionsApi';

interface ISubQuestion {
  id: number;
  product_id: number;
  type: string;
  content_en: string;
  content_th: string;
  parent_id: number;
  created_at: string | null;
  updated_at: string | null;
  options: any;
}

interface IOption {
  id: number;
  question_id: number;
  content_en: { label: string; value: string };
  content_th: { label: string; value: string };
  is_correct: number;
  created_at: string | null;
  updated_at: string | null;
  sub_question: ISubQuestion[];
}

export interface IQuestionInfo {
  id: number;
  product_id: number;
  type: string;
  content_en: string;
  content_th: string;
  created_at: string | null;
  updated_at: string | null;
  options: IOption[];
}

export interface IQuestionState {
  questions: IQuestionInfo[];
  errMess: string | null;
}

const initialState: IQuestionState = {
  questions: [],
  errMess: null,
};

export const fetchQuestion = createAsyncThunk(
  'question/fetchQuestion',
  async (productId: number) => {
    const {
      data: { data },
    } = await QuestionService.getQuestionList(productId);
    return data;
  }
);

export const postAnswer = createAsyncThunk(
  'question/postAnswer',
  async (
    { answer, callback }: { answer: any; callback: (answer_ids?: any) => void },
    { rejectWithValue }
  ) => {
    try {
      const {
        data: { data },
      } = await AnswerApi.createAnswer(answer);
      callback(data.answer_ids);
    } catch (e: any) {
      return rejectWithValue(e.response.data.messages);
    }
  }
);

export const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    setQuestion: (state, action: PayloadAction<IQuestionInfo[]>) => {
      state.questions = action.payload;
    },
    resetQuestions: (state) => {
      state.questions = initialState.questions;
    },
    resetErrMess: (state) => {
      state.errMess = initialState.errMess;
    },
  },
  extraReducers: {
    [fetchQuestion.fulfilled as any]: (state, action) => {
      state.questions = action.payload;
    },
    [postAnswer.rejected as any]: (state, action) => {
      state.errMess = action.payload;
    },
  },
});

export const { setQuestion, resetQuestions, resetErrMess } =
  questionSlice.actions;

export default questionSlice.reducer;
