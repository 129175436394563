import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MuiSelect, { SelectProps } from '@material-ui/core/Select';
import styled, { withTheme } from 'styled-components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Locale } from '../../constants';
import { switchLocale } from '../../utils/locales/i18n';

export interface ISelectOption {
  value: any;
  title: string;
}

export interface ILanguageSelectorProps extends SelectProps {
  disabled?: boolean;
}

const options: ISelectOption[] = [
  { value: Locale.ENG, title: 'EN' },
  { value: Locale.THAI, title: 'TH' },
];

const Select = withTheme(styled(MuiSelect)`
  &&& {
    height: 40px;
    width: 100%;
    border-radius: 6px;

    .MuiSelect-select:focus {
      background: none;
    }

    .MuiInputBase-input {
      color: ${({ theme }) => theme.palette.primary.main};
      font-weight: bold;
    }

    .MuiSelect-icon {
      color: ${({ theme }) => theme.palette.primary.main};
    }

    :not(.Mui-focused) .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.palette.info.main};
    }
  }
`);

const LanguageSelector = ({
  variant = 'outlined',
  value,
  ...rest
}: ILanguageSelectorProps) => {
  React.useEffect(() => {
    switchLocale(value as Locale);
  }, [value]);

  return (
    <FormControl variant={variant}>
      <Select
        data-testid="languageBar"
        IconComponent={KeyboardArrowDownIcon}
        color="primary"
        value={value}
        {...rest}
      >
        {options.map((option) => (
          <MenuItem dense key={option.value} value={option.value}>
            {option.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default React.memo(LanguageSelector);
