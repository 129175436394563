import httpClient from '../../utils/axios';

const ADMIN_AUTH_ENDPOINT_SUFFIX = '/api/admin/auth/token';
const ADMIN_GET_ROLE_ENDPOINT_SUFFIX = '/api/admin/get-role';

const AdminAuthApi = {
  adminLogin: (params: any) =>
    httpClient.post(ADMIN_AUTH_ENDPOINT_SUFFIX, params),
  getAdminRole: (token: string) =>
    httpClient.get(ADMIN_GET_ROLE_ENDPOINT_SUFFIX, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default AdminAuthApi;
