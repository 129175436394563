import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ProductApi from '../../apis/ProductApi';

interface IPlanPrice {
  id: number;
  from: number;
  to: number;
  price: string;
  plan_id: number;
  created_at: string;
  updated_at: string;
}

export interface IPlan {
  id: number;
  product_id: number;
  subscription_id: number;
  free_trial: boolean;
  name_en: string;
  name_th: string;
  short_desc_en: {
    title: string;
    value: string;
  }[];
  short_desc_th: {
    title: string;
    value: string;
  }[];
  description_en: string;
  description_th: string;
  created_at: string;
  updated_at: string;
  subscription?: any;
  plan_prices: IPlanPrice[];
}

export interface IProductInfo {
  id: number;
  name_en: string;
  name_th: string;
  short_desc_en: string;
  short_desc_th: string;
  description_en: string;
  description_th: string;
  image: string;
  created_at: string;
  updated_at: string;
  questions?: any[];
  plans: IPlan[];
}

export interface IProductCoverage {
  id: number;
  name_en: string;
  name_th: string;
  short_desc_en: string;
  short_desc_th: string;
  description_en: string;
  description_th: string;
  image: string;
  created_at: string;
  updated_at: string;
  plans: IPlan;
}

export interface IProductsState {
  products: IProductInfo[];
  productCoverages: IProductCoverage[];
  currentProductId?: number;
  currentPlanId?: number;
  currentRenewPrice?: string;
}

const initialState: IProductsState = {
  products: [],
  productCoverages: [],
};

export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async () => {
    const { data: response } = await ProductApi.getProductList();
    return response.data;
  }
);

export const fetchProductCoverages = createAsyncThunk(
  'products/fetchProductCoverages',
  async ({ productId, planId }: any) => {
    const {
      data: { data },
    } = await ProductApi.getProductCoverages(productId, planId);
    return data;
  }
);

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<IProductInfo[]>) => {
      state.products = action.payload;
    },
    setProductCoverages: (state, action: PayloadAction<IProductCoverage[]>) => {
      state.productCoverages = action.payload;
    },
    setCurrentProductId: (state, action: PayloadAction<number>) => {
      state.currentProductId = action.payload;
    },
    setCurrentPlanId: (state, action: PayloadAction<number>) => {
      state.currentPlanId = action.payload;
    },
    setCurrentRenewPrice: (state, action: PayloadAction<string>) => {
      state.currentRenewPrice = action.payload;
    },
    resetProductsState: (state) => {
      state.products = initialState.products;
      state.productCoverages = initialState.productCoverages;
    },
  },
  extraReducers: {
    [fetchProducts.fulfilled as any]: (state, action) => {
      state.products = action.payload;
    },
    [fetchProductCoverages.fulfilled as any]: (state, action) => {
      state.productCoverages = action.payload;
    },
  },
});

export const {
  setProducts,
  setProductCoverages,
  setCurrentProductId,
  setCurrentPlanId,
  setCurrentRenewPrice,
  resetProductsState,
} = productsSlice.actions;

export default productsSlice.reducer;
