import { Route, RouterProps, useHistory } from 'react-router-dom';
import React, { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setToken } from '../redux/slices/authSlice';
import useQuery from '../hooks/useQuery';
import httpClient from '../utils/axios';
import { Routes } from '../constants';

const ProtectedRoute = ({
  component: Component,
  layout: Layout,
  admin,
  ...rest
}: any) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const query = useQuery();

  const { token: adminToken } = useAppSelector((state) => state.adminAuth);
  const { token: persistToken } = useAppSelector((state) => state.auth);

  const paramToken = query.get('t');
  const token = paramToken || persistToken;

  useEffect(() => {
    if (!admin) {
      return;
    }

    if (admin && !adminToken) {
      history.push(Routes.ADMIN_LOGIN);
      return;
    }

    httpClient.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${adminToken}`;
  }, [adminToken]);

  useEffect(() => {
    if (admin) {
      return;
    }

    if (!token) {
      history.push(Routes.AUTH_404);
    } else {
      httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    if (paramToken) {
      dispatch(setToken(paramToken));
    }
  }, [token, paramToken]);

  return Layout ? (
    <Layout>
      <Route
        {...rest}
        render={(props: RouterProps) => <Component {...rest} {...props} />}
      />
    </Layout>
  ) : (
    <Route
      {...rest}
      render={(props: RouterProps) => <Component {...rest} {...props} />}
    />
  );
};

export default memo(ProtectedRoute);
