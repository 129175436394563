import { PrivacyPolicyTH, PrivacyPolicyPdpaConsentTH } from './privacyPolicy';
import { TermAndConditionsTH } from './termAndConditions';
import moment from 'moment';

export default {
  title: 'Thai title',
  locale: {
    en: 'English',
    th: 'Thailand',
  },
  menu: {
    home: 'หน้าแรก',
    balance: 'ยอดคงเหลือ',
    careShop: 'แคร์ช็อป',
    profile: 'โปรไฟล์',
  },
  common: {
    completeCare: 'ใช้ใจแคร์ ดูแลครบ',
    error: 'ผิดพลาด',
    comingSoon: 'เปิดใช้เร็วๆ นี้',
    adminSection: 'ส่วนผู้ดูแลระบบ',
    logout: 'ออกจากระบบ',
    filter: 'กรอง',
    reset: 'รีเซ็ต',
    exportAsCsv: 'ส่งออกเป็น CSV',
    male: 'ชาย',
    female: 'หญิง',
    rowsPerPage: 'แถวต่อหน้า',
    of: 'จาก',
    startDate: 'วันที่เริ่มต้น',
    endDate: 'วันที่สิ้นสุด',
    update: 'อัพเดท',
    cancel: 'ยกเลิก',
    editSuccessfully: 'แก้ไขสำเร็จ!',
    buyNow: 'ซื้อเลย!',
    startFreeTrial: 'รับความคุ้มครองฟรี! ',
    bookAnAppointment: 'จองคิว',
    oneMonth: '1 เดือน',
    moreDetail: 'รายละเอียดเพิ่มเติม',
    next: 'ถัดไป',
    goBackToHomepage: 'กลับสู่หน้าหลัก',
    confirm: 'ยืนยัน',
    no: 'ไม่ยืนยัน',
    bahtPerYear: 'บาท',
    bahtPerMonth: 'บาท/เดือน',
    accept: 'ยินยอม',
    decline: 'ปฏิเสธ',
    free: 'ทดลองใช้ฟรี',
    freeLabel: 'ฟรี!',
    expired: 'หมดอายุ',
    active: 'มีความคุ้มครอง',
    productExpired: 'ผลิตภัณฑ์ หมดอายุ',
  },
  page: {
    home: {
      products: 'ผลิตภัณฑ์',
      services: 'บริการ',
    },
    checkoutRejection: {
      refuseInsurance: 'ปฏิเสธการรับประกันภัย',
      apologize: 'ขออภัย',
      description:
        'น้องแคร์ต้องขอโทษเป็นอย่างสูง ที่ไม่สามารถอนุมัติแผนประกันนี้ได้ เนื่องจากเงื่อนไขความคุ้มครองของคุณ "ไม่ตรงตามเกณฑ์" ที่ได้ระบุไว้ในแผนประกันนี้ แต่ทุกคนยังสามารถเปรียบเทียบ และเลือกแผนประกันอื่น ๆ ได้ที่ rabbitcare.com หรือหากต้องการสอบถามข้อมูลเพิ่มเติม สามารถติดต่อน้องแคร์ได้ที่เบอร์ 1438 ตลอด 24 ชั่วโมง',
    },
    thankYou: {
      dear: 'เรียน คุณ{{name}}',
      thankYouText:
        'Thank you for choosing rabbit finance. Find below the summary and next steps for your policy underwriting:',
      thankYouTextFreeTrial:
        'Thank you, your 30 day free trial started. Find below the summary and next steps.',
      showCoverageDetail: 'แสดงรายละเอียดความคุ้มครอง',
      nextSteps: 'ขั้นตอนต่อไป',
      totalPrice: 'ราคาสุทธิ',
      beCovered: 'ความคุ้มครองของคุณ',
      receivePolicy: 'การรับกรมธรรม์',
      textIncludeTax: '*ราคานี้ รวมภาษีมูลค่าเพิ่ม 7% และอากรแสตมป์',
      goBackToHomepage: 'กลับสู่หน้าหลัก',
      rabbitRewards: 'แรบบิทรีวอร์ดส',
      point: 'พอยท์',
      showDetailOfRR: 'แสดงรายละเอียดแรบบิทรีวอร์ดส',
      congratulations: 'ขอแสดงความยินดี',
      receivePoint: `คุณจะได้รับพอยท์อัตโนมัติหลังจากหมดระยะเวลาช่วง free look period<br/>
        หรือ ภายหลังจาก 15 วัน นับตั้งแต่วันที่กรมธรรม์ประกันภัยเริ่มมีผลคุ้มครอง`,
      insurancePolicy: 'โดยคุณต้องไม่ทำการยกเลิกกรมธรรม์ในช่วงเวลาดังกล่าว',
      checkPoint: 'เช็คพอยท์ของคุณได้ที่นี่',
      rabbitRewardLink: 'คลิกเพื่อรับคะแนนแรบบิทรีวอร์ดส',
    },
    serviceDetail: {
      time: 'ครั้ง',
    },
    productDetail: {
      coverage: 'ความคุ้มครอง',
      condition: 'เงื่อนไข',
    },
    healthQuestions: {
      healthQuestions: 'คำถามสุขภาพ',
    },
    checkout: {
      checkout: 'ตรวจสอบรายการสั่งซื้อ',
      personalInformation: 'กรุณากรอกข้อมูลส่วนตัว',
      title: 'คำนำหน้า',
      name: 'ชื่อจริง',
      surname: 'นามสกุล',
      idCardNumber: 'หมายเลขบัตรประชาชน',
      phoneNumber: 'เบอร์โทรศัพท์',
      emailAddress: 'อีเมล',
      dateOfBirth: 'วัน/เดือน/ปีเกิด',
      address: 'รายละเอียดที่อยู่',
      addressLabel: 'ที่อยู่',
      province: 'จังหวัด',
      district: 'เขต/อำเภอ',
      subDistrict: 'แขวง/ตำบล',
      postcode: 'รหัสไปรษณีย์',
      additionalQuestion: 'กรุณาตอบคำถามเพิ่มเติม',
      weightKg: 'น้ำหนัก (กก.)',
      heightCm: 'ส่วนสูง (ซม.)',
      occupation: 'อาชีพหลัก',
      subOccupation: 'อาชีพย่อย',
      confirm: 'ยืนยัน',
      addCardDetails: 'รายละเอียดการชำระเงิน',
      selectCard: 'เลือกบัตร',
      newCard: 'บัตรใบใหม่',
      cardNo: 'หมายเลขบัตร',
      nameOfCardHolder: 'ชื่อผู้ถือบัตร',
      expiryDate: 'วันหมดอายุ',
      expiryDateYear: 'วันหมดอายุปี',
      cvv: 'รหัสหลังบัตร',
      saveCardDetails: 'บันทึกรายละเอียดบัตร',
      setToDefault: 'ตั้งเป็นค่าเริ่มต้น',
      shortDetails:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      informCustomer:
        '* หากท่านทำการชำระเงินต่ออายุแล้ว ระบบจะทำการชำระเงินต่ออายุอัตโนมัติทุกเดือน ในวันก่อนกรมธรรม์หมดอายุ 1 วัน',
      checkoutForm: {
        selectPlaceholder: '--เลือก--',
      },
    },
    checkoutResult: {
      paymentUnsuccessful: 'พบข้อผิดพลาดเกิดขึ้น',
      paymentUnsuccessfulSubtitle:
        'ขออภัย เราไม่สามารถทำรายการได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง หรือติดต่อ Rabbit Care Call Center 1438',
      validCardUnsuccessful: 'พบข้อผิดพลาดเกิดขึ้น',
      validCardUnsuccessfulSubtitle1: 'หมายเลยบัตรไม่ถูกต้อง',
      validCardUnsuccessfulSubtitle2: 'กรุณาลองใหม่อีกครั้ง',
      cancelLabel: 'ปิด',
      laterLabel: 'ภายหลัง',
      retryLabel: 'ลองอีกครั้ง',
    },
    consent: {
      consent: 'การขอความยินยอม',
      rabbitRewardConsent: `ข้าพเจ้ายอมรับ 
      <a href="https://rewards.rabbit.co.th/th/rr/terms" target="_blank">ข้อกำหนดและเงื่อนไขในการบริการ</a> และรับทราบ
      <a href="https://rewards.rabbit.co.th/th/rr/privacy" target="_blank">นโยบายความเป็นส่วนตัว </a> 
      ของบริษัท แรบบิท รีวอร์ดส จำกัด`,
      warningText: `หากท่านไม่ยินยอม ท่านจะไม่ได้รับคะแนนสะสมและสิทธิประโยชน์อื่นๆ จากแรบบิท รีวอร์ดส`,
    },
    pdpaConsent: {
      checkbox1: {
        confirmLine1: `ข้าพเจ้ายินยอมให้ บริษัท แรบบิท แคร์ โบรคเกอร์ จำกัด บริษัทในเครือ และบริษัทย่อย ตลอดจนคู่ค้าทางธุรกิจของบริษัทเหล่านี้ เก็บ ใช้ และ/หรือ เปิดเผยข้อมูลส่วนบุคคลที่ละเอียดอ่อนของข้าพเจ้า เพื่อวัตถุประสงค์ดังต่อไปนี้:
          (ก) <i>ข้อมูลส่วนบุคคลที่ละเอียดอ่อนจากผลิตภัณฑ์หรือบริการที่เกี่ยวข้องกับการประกันภัย รวมถึงการให้บริการที่ปรึกษาทางการแพทย์และเภสัชกรรมทางไกล</i> (เช่น ข้อมูลสุขภาพ ความทุพพลภาพ) เพื่อลงทะเบียนและให้ข้าพเจ้าได้ใช้ผลิตภัณฑ์หรือบริการที่เกี่ยวข้องกับการประกันภัยรวมถึงการให้บริการที่ปรึกษาทางการแพทย์และเภสัชกรรมทางไกล และ 
          (ข) <i>ข้อมูลสุขภาพ</i> เพื่อทำธุรกรรมทางการเงินและบริการที่เกี่ยวข้องกับการชำระเงินให้เสร็จลุล่วงไป (เช่น การจ่ายเงินค่าสินไหมทดแทน) ที่ต้องอาศัยความยินยอมจากท่าน รายละเอียดตาม`,
        condition1:
          'ข้อมูลส่วนบุคคลที่ละเอียดอ่อนจากผลิตภัณฑ์หรือบริการที่เกี่ยวข้องกับการประกันภัย รวมถึงการให้บริการที่ปรึกษาทางการแพทย์และเภสัชกรรมทางไกล ',
        confirmLine2:
          'เช่น ข้อมูลสุขภาพ ความทุพพลภาพ) เพื่อลงทะเบียนและให้ข้าพเจ้าได้ใช้ผลิตภัณฑ์หรือบริการที่เกี่ยวข้องกับการประกันภัยรวมถึงการให้บริการที่ปรึกษาทางการแพทย์และเภสัชกรรมทางไกล และ (ข) ',
        condition2: 'ข้อมูลสุขภาพ',
        confirmLine3:
          'เพื่อทำธุรกรรมทางการเงินและบริการที่เกี่ยวข้องกับการชำระเงินให้เสร็จลุล่วงไป (เช่น การจ่ายเงินค่าสินไหมทดแทน) ที่ต้องอาศัยความยินยอมจากท่าน รายละเอียดตาม ',
      },
      checkbox2: {
        confirmLine1:
          'ข้าพเจ้ายินยอมให้ บริษัท แรบบิท แคร์ โบรคเกอร์ จำกัด บริษัทในเครือ และบริษัทย่อย ตลอดจนคู่ค้าทางธุรกิจของบริษัทเหล่านี้ เก็บ ใช้ และ/หรือ เปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้า เช่น ชื่อ นามสกุล อีเมล์ เพื่อวัตถุประสงค์ดังต่อไปนี้: (ก) เพื่อการตลาดและการติดต่อสื่อสาร (ข) เพื่อการบริการวิเคราะห์ข้อมูล และ (ค) เพื่อธุรกิจอื่นๆ (เช่น การตลาดดิจิทัล การธนาคารและการเงิน โปรแกรมสะสมคะแนนและของรางวัล ซึ่งรวมถึงสินค้าและบริการที่เกี่ยวข้องอื่นๆ) ที่ต้องอาศัยความยินยอมจากท่าน รายละเอียดตาม ',
      },
      privacyPolicy: 'นโยบายความเป็นส่วนตัวฯ',
      termAndCondition: 'ข้อกำหนดและเงื่อนไข ',
      confirmText1: 'ท่านยอมรับ ',
      confirmText2:
        'ของการใช้บริการที่ปรึกษาทางการแพทย์และเภสัชกรรมทางไกลที่ ซึ่งให้บริการโดยบริษัท ชีวีบริรักษ์ จำกัด',
      acceptConsent: 'ยินยอม',
      refuseConsent: 'ปฏิเสธ',
    },
    lastCoverage: {
      lastCoverageDate: 'วันสุดท้ายที่ได้รับความคุ้มครอง',
      description: 'กรุณากรอกวันสิ้นสุดความคุ้มครอง',
      placeHolderDateField:
        'กรุณาเลือกวัน/เดือน/ปีที่ระบุในกรมธรรม์ว่าเป็นวันสิ้นสุดความคุ้มครอง',
    },
    confirmCancel: {
      confirmCancellation: 'ยืนยันการยกเลิกกรมธรรม์',
      description:
        'ตรวจสอบยอดเงินคืนค่าเบี้ยประกันภัย ที่คำนวณจากวันที่สิ้นสุดความคุ้มครอง',
    },
    cancellationSuccessful: {
      dear: 'เรียน คุณ{{name}}',
      cancelText:
        'การยกเลิกกรมธรรม์ของคุณเสร็จสิ้นแล้ว คุณสามารถอ่านรายละเอียดการยกเลิกกรมธรรม์ด้านล่างนี้',
    },
    currentCoverages: {
      pageTitle: 'ความคุ้มครองปัจจุบัน',
      confirmPolicy:
        'หากยกเลิกกรมธรรม์ดังกล่าว คุณจะไม่ได้รับความคุ้มครองด้านล่างนี้อีกต่อไป',
      confirmSection: 'คุณต้องการยืนยันการยกเลิกกรมธรรม์ใช่หรือไม่',
    },
    cancellationReasons: {
      title: 'เหตุผลการขอยกเลิกกรมธรรม์',
      reason: 'ทำไมคุณถึงต้องการยกเลิกกรมธรรม์',
      reasonDetail: 'กรุณาระบุรายละเอียดเพิ่มเติม',
      placeHolderCombobox: 'โปรดบอกเราว่าเกิดอะไรขึ้น',
      placeHolderTextField: 'กรุณาชี้แจงรายละเอียดเพิ่มเติม',
    },
    mySubscriptions: {
      yourPurchase: 'คำสั่งซื้อของคุณ',
      mySubscriptions: 'การสมัครของฉัน',
      myCancelledProducts: 'รายการที่ถูกยกเลิกของฉัน',
      product: 'ผลิตภัณฑ์',
      status: 'สถานะ',
      nextPayment: 'การชำระเงินครั้งต่อไป',
      total: 'รวม',
      renew: 'ต่ออายุ',
      view: 'ดูรายละเอียด',
      rabbitRewards: 'แรบบิทรีวอร์ดส',
      point: 'พอยท์',
      at: 'เวลา',
    },
    mySubscriptionDetail: {
      status: 'สถานะ',
      policyNumber: 'เลขที่กรมธรรม์',
      PAPolicy: 'กรมธรรม์อุบัติเหตุ',
      healthPolicy: 'กรมธรรม์สุขภาพ',
      startCoverageDate: 'วันเริ่มต้นความคุ้มครอง',
      endCoverageDate: 'วันสิ้นสุดความคุ้มครอง',
      lastPaymentDate: 'ชำระเบี้ยฯครั้งล่าสุด',
      nextPaymentDate: 'วันกำหนดชำระเบี้ยฯครั้งต่อไป',
      total: 'รวม',
      refund: 'ยอดเงินคืนค่าเบี้ยฯ',
      cancel: 'ยกเลิก',
    },
    privacyPolicy: {
      title: 'นโยบายความเป็นส่วนตัว',
      text: PrivacyPolicyTH,
    },
    privacyPolicyPdpaConsent: {
      title: 'นโยบายความเป็นส่วนตัว',
      text: PrivacyPolicyPdpaConsentTH,
    },
    termAndCondition: {
      title: 'ข้อกำหนดและเงื่อนไข',
      text: TermAndConditionsTH,
    },
    error: {
      oops: 'ขออภัย',
      somethingWentWrong: 'เกิดข้อผิดพลาดบางประการ กรุณาลองใหม่อีกครั้ง',
      goBack: 'กลับสู่หน้าหลัก',
    },
    refundError: {
      oops: 'ขออภัย',
      somethingWentWrong:
        'น้องแคร์ต้องขอโทษเป็นอย่างสูง ที่คุณไม่สามารถยกเลิกกรรมธรรม์นี้ได้ เนื่องจาก ไม่ตรงตามเงื่อนไขของบริษัทประกันฯ หากคุณต้องการสอบถามข้อมูลเพิ่มเติม สามารถติดต่อน้องแคร์ได้ที่เบอร์ 1438 ตลอด 24 ชั่วโมง',
      goBack: 'กลับสู่หน้าหลัก',
    },
  },
  admin: {
    product: {
      productManagement: 'การจัดการผลิตภัณฑ์',
      id: 'ไอดี',
      productId: 'รหัสสินค้า',
      image: 'ภาพ',
      name_en: 'ชื่อภาษาอังกฤษ',
      name_th: 'ชื่อไทย',
      short_desc_en: 'คำอธิบายสั้น ๆ ภาษาอังกฤษ',
      short_desc_th: 'คำอธิบายโดยย่อ',
      description_en: 'คำอธิบายภาษาอังกฤษ',
      description_th: 'คำอธิบายภาษาไทย',
      status: 'สถานะ',
      action: 'ดูรายละเอียด',
      productNotFound: 'ไม่พบสินค้า',
      saveChanges: 'บันทึกการเปลี่ยนแปลง',
      backToList: 'กลับไปที่รายการ',
      consentDetails: 'รายละเอียดการยินยอม',
      english: 'ภาษาอังกฤษ',
      thai: 'ไทย',
      number: 'จำนวน',
      title: 'หัวข้อ',
      content: 'เนื้อหา',
      option: 'ตัวเลือกที่ {{option_no}}',
      warningText: 'ข้อความเตือน',
      orderPosition: 'ลำดับ',
    },
    service: {
      serviceManagement: 'การจัดการบริการ',
      id: 'ไอดี',
      serviceId: 'รหัสบริการ',
      image: 'ภาพ',
      name_en: 'ชื่อภาษาอังกฤษ',
      name_th: 'ชื่อไทย',
      short_desc_en: 'คำอธิบายสั้น ๆ ภาษาอังกฤษ',
      short_desc_th: 'คำอธิบายโดยย่อ',
      description_en: 'คำอธิบายภาษาอังกฤษ',
      description_th: 'คำอธิบายภาษาไทย',
      status: 'สถานะ',
      action: 'ดูรายละเอียด',
      serviceNotFound: 'ไม่พบบริการ',
      saveChanges: 'บันทึกการเปลี่ยนแปลง',
      backToList: 'กลับไปที่รายการ',
      products: 'สินค้า',
      delete: 'ลบ',
      selectProduct: 'เลือกสินค้า',
      add: 'เพิ่ม',
    },
    customer: {
      customer: 'ลูกค้า',
      customerManagement: 'การจัดการลูกค้า',
      keyword: 'คำสำคัญ',
      keywordPlaceholder: 'ค้นหาตามชื่อ อีเมล เบอร์โทร เลขบัตรประชาชน',
      purchasedProduct: 'สินค้าที่ซื้อ',
      selectProducts: 'เลือกสินค้า',
      lastPurchaseDate: 'วันที่ซื้อล่าสุด',
      createDate: 'วันที่สร้าง',
      updateDate: 'อัพเดทวันที่',
      id: 'ไอดี',
      customerId: 'รหัสลูกค้า',
      title: 'สมญา',
      name: 'ชื่อ',
      firstName: 'ชื่อจริง',
      lastName: 'นามสกุล',
      email: 'อีเมล',
      gender: 'เพศ',
      dateOfBirth: 'วันเกิด',
      nationality: 'สัญชาติ',
      mobileNumber: 'เบอร์มือถือ',
      maritalStatus: 'สถานภาพการสมรส',
      weight: 'น้ำหนัก',
      height: 'ส่วนสูง',
      occupation: 'อาชีพ',
      subOccupation: 'อาชีพย่อย',
      action: 'ดูรายละเอียด',
      customerNotFound: 'ไม่พบลูกค้า',
      idCard: 'บัตรประจำตัวประชาชน',
      passport: 'หนังสือเดินทาง',
      landlineNumber: 'หมายเลขโทรศัพท์พื้นฐาน',
      language: 'ภาษา',
      backToList: 'กลับไปที่รายการ',
      orderDetails: 'รายละเอียดการสั่งซื้อ',
    },
    order: {
      orderManagement: 'การจัดการคำสั่งซื้อ',
      keyword: 'คำสำคัญ',
      keywordPlaceholder: 'ค้นหาตามชื่อ อีเมล เบอร์โทร เลขบัตรประชาชน',
      selectProducts: 'เลือกสินค้า',
      selectCustomers: 'เลือกลูกค้า',
      orderDetails: 'รายละเอียดการสั่งซื้อ',
      id: 'ไอดี',
      orderId: 'รหัสคำสั่งซื้อ',
      status: 'สถานะ',
      product: 'ผลิตภัณฑ์',
      price: 'ราคา',
      expireDate: 'วันหมดอายุกรมธรรม์',
      purchasedProductPlan: 'แผนผลิตภัณฑ์ที่ซื้อ',
      orderDate: 'วันสั่ง',
      activeDate: 'วันที่ใช้งาน',
      lastCoverageDate: 'วันสุดท้ายที่ได้รับความคุ้มครอง',
      lastPaymentDate: 'วันกำหนดชำระเบี้ยฯครั้งล่าสุด',
      nextPaymentDate: 'วันกำหนดชำระเบี้ยฯครั้งต่อไป',
      policyId: 'รหัสกรมธรรม์',
      PAPolicy: 'กรมธรรม์อุบัติเหตุ',
      healthPolicy: 'กรมธรรม์สุขภาพ',
      policyNumber: 'เลขที่กรมธรรม์',
      action: 'ดูรายละเอียด',
      noOrders: 'ไม่มีคำสั่ง',
      backToList: 'กลับไปที่รายการ',
      name_en: 'ชื่อภาษาอังกฤษ',
      name_th: 'ชื่อไทย',
      paymentAmount: 'จำนวนเงินที่ชำระ',
      orderAddress: 'ที่อยู่ในการสั่งซื้อ',
      address: 'ที่อยู่',
      province: 'จังหวัด',
      district: 'อำเภอ',
      subdistrict: 'ตำบล',
      postcode: 'รหัสไปรษณีย์',
      email: 'อีเมล',
      mobileNumber: 'เบอร์มือถือ',
      txid: 'เลขที่ธุรกรรม',
      invoiceNumber: 'เลขที่ใบแจ้งหนี้',
      sureMoveStatusToCancel:
        'Are you sure you want to change status to cancel ?',
      cancelOrder: 'Cancel order',
    },
    thankYou: {
      thankYouPage: 'หน้าขอบคุณ',
      thankYouPageDetails: 'รายละเอียดหน้าขอบคุณ',
      id: 'ไอดี',
      type: 'พิมพ์',
      content_en: 'เนื้อหา (ภาษาอังกฤษ)',
      content_th: 'เนื้อหา (ภาษาไทย)',
      receivePolicy: 'รับนโยบาย',
      beCovered: 'ถูกปกคลุม',
      nextStepContent_en: 'เนื้อหา {{nextStep}} (ภาษาอังกฤษ)',
      nextStepContent_th: 'เนื้อหา {{nextStep}} (ภาษาไทย)',
      action: 'ดูรายละเอียด',
      createdAt: 'วันที่สร้าง',
      updatedAt: 'ปรับปรุงล่าสุด',
      backToList: 'กลับไปที่รายการ',
    },
  },
  errors: {
    required: 'จำเป็นต้องมีข้อมูล {{field}}',
  },
  footer: {
    companyName: 'บริษัท แรบบิท แคร์ โบรคเกอร์ จำกัด',
    lifeBrokerID: 'ใบอนุญาตนายหน้าประกันชีวิต เลขที่ ช00011/2559',
    NonLifeBrokerID: 'ใบอนุญาตนายหน้าประกันวินาศภัย เลขที่ ว00021/2557',
    copyright: `สงวนลิขสิทธิ์ พ.ศ. ${moment()
      .add(543, 'years')
      .format('YYYY')}`,
    groupName: 'บริษัท แรบบิท แคร์ โบรคเกอร์ จำกัด (ในเครือ BTS group)',
  },
};
