import { Color } from './variants';
import { rgba } from 'polished';

const overrides = {
  MuiCardHeader: {
    action: {
      marginTop: '-4px',
      marginRight: '-4px',
    },
  },
  MuiButton: {
    textSecondary: {
      color: Color.GREY_LIGHT,
      '&:hover': {
        backgroundColor: Color.BLUE_WHITE,
      },
    },
  },
  MuiPickersDay: {
    day: {
      fontWeight: '300',
    },
  },
  MuiPickersYear: {
    root: {
      height: '64px',
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      marginTop: '6px',
    },
  },
  MuiPickersCalendarHeader: {
    iconButton: {
      backgroundColor: 'transparent',
      '& > *': {
        backgroundColor: 'transparent',
      },
    },
    switchHeader: {
      marginTop: '2px',
      marginBottom: '4px',
    },
  },
  MuiPickersClock: {
    container: {
      margin: '32px 0 4px',
    },
  },
  MuiPickersClockNumber: {
    clockNumber: {
      left: 'calc(50% - 16px)',
      width: '32px',
      height: '32px',
    },
  },
  MuiPickerDTHeader: {
    dateHeader: {
      '& h4': {
        fontSize: '2.125rem',
        fontWeight: 400,
      },
    },
    timeHeader: {
      '& h3': {
        fontSize: '3rem',
        fontWeight: 400,
      },
    },
  },
  MuiPickersTimePicker: {
    hourMinuteLabel: {
      '& h2': {
        fontSize: '3.75rem',
        fontWeight: 300,
      },
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      '& h4': {
        fontSize: '2.125rem',
        fontWeight: 400,
      },
    },
  },
  MuiSwitch: {
    root: {
      width: 48,
      height: 25,
      padding: 0,
      margin: 4,
    },
    switchBase: {
      padding: 2,
      color: '#5c5b5b',
    },
    colorSecondary: {
      '&$checked': {
        transform: 'translateX(23px)',
        color: '#fff',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#C3D9F2',
          borderColor: '#C3D9F2',
        },
      },
    },
    thumb: {
      width: 21,
      height: 21,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid #5c5b5b`,
      borderRadius: 50,
      opacity: 1,
      backgroundColor: '#fff',
    },
    checked: {},
  },
  MuiTableRow: {
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: rgba(Color.BLUE_LIGHTER, 0.3),
      },
      '&:nth-of-type(odd)': {
        backgroundColor: Color.WHITE,
      },
    },
  },
  MuiTableCell: {
    head: {
      backgroundColor: Color.BLUE_LIGHTER,
      color: Color.BLACK_LIGHT,
    },
    body: {
      fontSize: 14,
    },
  },
  MuiSelect: {
    outlined: {
      height: 50,
      borderRadius: 10,
    },
  },
  MuiOutlinedInput: {
    root: {
      height: 50,
      borderRadius: 10,
      color: Color.BLACK_LIGHT,
      fontSize: 16,
      backgroundColor: '#FFF',
      '&$disabled': {
        backgroundColor: '#E2E3ED',
        color: Color.BLACK_LIGHT,
      },
      '&$disabled $notchedOutline': {
        border: 'none',
      },
    },
    notchedOutline: {
      borderRadius: 10,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#DEE8FF',
    },
    input: {
      padding: '14px 20px',
    },
  },
};

export default overrides;
