import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import AnswerApi from '../../apis/AnswerApi';
import consentApi from '../../apis/ConsentApi';
import rabbitRewardApi from '../../apis/RabbitRewardApi';

interface ISelections {
  text: string;
  title: string;
  idCardNumber?: string;
  value?: string;
}

interface IConsentDetails {
  type: string;
  title: string;
  content?: string;
  selection?: ISelections[];
  warningText?: string;
  is_mandatory?: boolean;
}

interface IConsentInfo {
  productId: number;
  consentDetails: {
    en: IConsentDetails[];
    th: IConsentDetails[];
  };
}

export interface IConsentState {
  consent: IConsentInfo | null;
  consentMarketing: IConsentInfo | null;
  rabbitMember: boolean;
  loading: string;
}

interface IConsentService {
  customer_id: number | null;
  value: boolean;
  question_no: number;
}

const initialState: IConsentState = {
  consent: null,
  consentMarketing: null,
  rabbitMember: false,
  loading: 'idle',
};

export const fetchConsents = createAsyncThunk(
  'consents/fetchConsents',
  async (id: number) => {
    const {
      data: { data },
    } = await consentApi.getConsent(id);
    return data;
  }
);

export const fetchConsentsPopup = createAsyncThunk(
  'consents/fetchConsentsPopup',
  async (id: number) => {
    const {
      data: { data },
    } = await consentApi.getConsentMarketing(id);
    return data;
  }
);

export const checkRRmember = createAsyncThunk(
  'consents/checkRRMember',
  async () => {
    const { data: data } = await rabbitRewardApi.checkRRmember();
    return data;
  }
);

export const syncRRMember = createAsyncThunk(
  'consents/syncRRMember',
  async () => {
    const { data: response } = await rabbitRewardApi.syncRRMember();

    return response;
  }
);

export const createServiceConsent = createAsyncThunk(
  'consent/create-service-consent',
  async (payload: IConsentService[]) => {
    const { data: data } = await AnswerApi.createServiceConsentAnswer(payload);
    return data;
  }
);

export const consentSlice = createSlice({
  name: 'consent',
  initialState,
  reducers: {
    setConsent: (state, action: PayloadAction<IConsentInfo>) => {
      state.consent = action.payload;
    },
    resetConsent: (state) => {
      state.consent = initialState.consent;
    },
    setRRMember: (state, action) => {
      state.rabbitMember = action.payload;
    },
  },
  extraReducers: {
    [fetchConsents.fulfilled as any]: (state, action) => {
      state.consent = action.payload;
    },
    [fetchConsentsPopup.fulfilled as any]: (state, action) => {
      state.consentMarketing = action.payload;
    },
    [syncRRMember.fulfilled as any]: (state, action) => {
      state.rabbitMember = action.payload;
    },
    [checkRRmember.fulfilled as any]: (state, action) => {
      const res = action.payload.data.result;
      if (res?.is_binding && res?.is_rr_member) {
        state.rabbitMember = true;
      }
    },
  },
});

export const { setConsent, resetConsent, setRRMember } = consentSlice.actions;

export const getRRMember = () => async (dispatch: any) => {
  dispatch(checkRRmember()).then((result: any) => {
    const res = result.payload.data.result;
    if (res?.is_rr_member === false || res?.is_binding === false) {
      dispatch(syncRRMember());
    }
  });
};

export default consentSlice.reducer;
