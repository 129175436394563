import httpClient from '../utils/axios';

const ANSWER_ENDPOINT_SUFFIX = '/api/answer';

const AnswerApi = {
  createAnswer: (params: any) =>
    httpClient.post(`${ANSWER_ENDPOINT_SUFFIX}/create`, params),
  createServiceConsentAnswer: (params: any) =>
    httpClient.post(`${ANSWER_ENDPOINT_SUFFIX}/service-consent/create`, params),
};

export default AnswerApi;
