import httpClient from '../utils/axios';

const CONSENT_ENDPOINT_SUFFIX = '/api/consent';
const CONSENT_ADMIN_ENDPOINT_SUFFIX = '/api/admin/consent';

const ConsentApi = {
  getConsent: (productId: number) =>
    httpClient.get(`${CONSENT_ENDPOINT_SUFFIX}/${productId}`),
  getConsentMarketing: (productId: number) =>
    httpClient.get(`${CONSENT_ENDPOINT_SUFFIX}/marketing/${productId}`),
  getAdminConsentDetail: (productId: number) =>
    httpClient.get(`${CONSENT_ADMIN_ENDPOINT_SUFFIX}/${productId}`),
  updateConsent: (consent: any) =>
    httpClient.post(
      `${CONSENT_ADMIN_ENDPOINT_SUFFIX}/${consent.productId}`,
      consent
    ),
};

export default ConsentApi;
