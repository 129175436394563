import React from 'react';
import { Button as MuiButton, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAppSelector } from '../../hooks';
import { IMGIX_DOMAIN } from '../../constants';

const Container = styled(Box)`
  background-image: url(${IMGIX_DOMAIN}/png/bg.png?auto=compress&fit=clip);
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  height: 100vh;
`;

const Button = styled(MuiButton)`
  &&& {
    margin-top: 30px;
    width: 90%;
    height: 60px;
    font-size: 18px;
    font-weight: bold;
  }
`;

function ErrorFallback() {
  const { t } = useTranslation();
  const { error } = useAppSelector((state) => state.global);

  return (
    <Container
      paddingTop={25}
      justifyContent="center"
      data-testid="error-component"
    >
      <img
        src={`${IMGIX_DOMAIN}/svg/sorry.svg?auto=compress&fit=clip`}
        width="100%"
      />
      <Typography
        component="h1"
        variant="h1"
        align="center"
        color="primary"
        gutterBottom
      >
        {t('page.error.oops')}
      </Typography>
      <Typography component="h5" variant="h5" gutterBottom>
        {error && error.message}
      </Typography>
      <Typography component="h5" variant="h5" align="center" gutterBottom>
        {t('page.error.somethingWentWrong')}
      </Typography>
      <Box display="flex" justifyContent="center">
        <Button
          color="primary"
          variant="contained"
          onClick={() => window.location.replace('/')}
        >
          {t('page.error.goBack')}
        </Button>
      </Box>
    </Container>
  );
}
export default ErrorFallback;
