import TagManager from 'react-gtm-module';
import { convertPriceStringToNumber } from '../convertCurrencyNumber';
import { ProductType } from '../../constants';

export type ProductDetail = {
  id: string;
  name: string;
  pageName: string;
  price: string;
  variant: number;
  position: number;
};

const clearPrevEcom = () => {
  TagManager.dataLayer({
    dataLayer: {
      ecommerce: null,
    },
  }); // Clear the previous ecommerce object.
};

export const productClickTracking = (
  encryptedId: string,
  data: ProductDetail
) => {
  clearPrevEcom();
  TagManager.dataLayer({
    dataLayer: {
      user_id: encryptedId,
      events: 'productClick',
      ecommerce: {
        click: {
          actionField: { list: data.pageName },
          products: [
            {
              name: data.name,
              id: data.id,
              price: convertPriceStringToNumber(data.price),
              brand: 'BKI', //The insurer name, currently there’s no available field so it would be fixed value
              category: 'Health', //The category name, currently there’s no available field so it would be fixed value
              variant: data.variant,
              position: data.position,
            },
          ],
        },
      },
    },
  });
};

export const productImpressionTracking = (encryptedId: string, data: any) => {
  clearPrevEcom();
  TagManager.dataLayer({
    dataLayer: {
      user_id: encryptedId,
      ecommerce: {
        currencyCode: 'THB',
        impressions: [
          {
            name: data.name,
            id: data.product_id,
            price:
              data.type === ProductType.VIRTUAL
                ? 0
                : convertPriceStringToNumber(data.price),
            brand: data.type === ProductType.REAL ? 'BKI' : 'BLA',
            category: data.type === ProductType.REAL ? 'Health' : 'Lead',
            ...(data.type === ProductType.REAL && {
              variant: data.plan_price_id,
            }),
            list: data.page,
            position: data.count,
          },
        ],
      },
    },
  });
};

export const productDetailTracking = (encryptedId: string, data: any) => {
  clearPrevEcom();
  TagManager.dataLayer({
    dataLayer: {
      user_id: encryptedId,
      ecommerce: {
        detail: {
          actionField: { list: 'Home Page' }, // 'detail' actions have an optional list property.
          products: [
            {
              name: data.name,
              id: data.product_id,
              price: data.free_trial
                ? 0
                : convertPriceStringToNumber(data.plan_prices[0]?.price),
              brand: 'BKI',
              category: 'Health',
              variant: data.plan_prices[0]?.id,
            },
          ],
        },
      },
    },
  });
};

export const productCheckoutTracking = (
  encryptedId: string,
  stepNo: number,
  checkoutOption: any,
  data: any
) => {
  clearPrevEcom();
  TagManager.dataLayer({
    dataLayer: {
      user_id: encryptedId,
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: { step: stepNo },
          products: [
            {
              name: data.name,
              id: data.id,
              price: convertPriceStringToNumber(data.price),
              brand: 'BKI',
              category: 'Health',
              variant: data.duration,
              quantity: 1,
            },
          ],
        },
      },
    },
  });

  clearPrevEcom();
  TagManager.dataLayer({
    dataLayer: {
      event: 'checkoutOption',
      ecommerce: {
        checkout_option: {
          actionField: { step: stepNo, option: checkoutOption },
        },
      },
    },
  });
};

export const purchaseTracking = (
  encryptedId: string,
  orderId: any,
  isRR: any,
  metricPoint: any,
  data: any
) => {
  clearPrevEcom();
  TagManager.dataLayer({
    dataLayer: {
      user_id: encryptedId,
      ecommerce: {
        purchase: {
          actionField: {
            id: orderId, // The order id
            affiliation: 'My Rabbit App',
            revenue: convertPriceStringToNumber(data.total), // Total transaction value (incl. tax and shipping)
            ...(isRR && { option: 'Rabbit Reward Member' }), // Add when the customer is RR member, or give the RR consent
          },
          products: [
            {
              name: data.name,
              id: data.id,
              price: convertPriceStringToNumber(data.price),
              brand: 'BKI',
              category: 'Health',
              variant: data.duration,
              quantity: 1,
              ...(isRR && { metric1: metricPoint }), // When the customer is RR member, track the earned points here
            },
          ],
        },
      },
    },
  });
};
