import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import OrderApi, {
  DEFAULT_PER_PAGE,
  IOrderExportFilter,
} from '../../../apis/OrderApi';
import { OrderStatus } from '../../../constants';
import { CancelOrderParams } from '../../../utils/types';
import { ICustomer } from './adminCustomerSlice';

export interface IOrder {
  active_date: string;
  address: string;
  card_info_id: number;
  created_at: string;
  customer_id: number;
  district_code: string;
  email: string;
  expired_date: string;
  format_grand_total_price: string;
  format_sub_total_price: string;
  format_total_price: string;
  grandTotal: number;
  height: any;
  id: number;
  last_coverage_date: any;
  last_payment_date: string;
  next_payment_date: string;
  occupation_code: any;
  ordered_date: string;
  phone_number: number;
  plan_price_id: number;
  policy_id: any;
  PA_policy: string;
  health_policy: string;
  policy_number: string;
  postcode: string;
  product: any;
  province_code: string;
  status: string;
  subTotal: number;
  sub_district_code: string;
  sub_occupation_code: any;
  title: string;
  total: string;
  updated_at: string;
  weight: number;
  product_plan: string;
  customer: ICustomer;
  province: string;
  district: string;
  sub_district: string;
  plan_price: any;
  transaction: any;
  txid: string;
  hide_cancel: boolean;
}

interface IPageOptions {
  currentPage: number;
  perPage: number;
  total: number;
}

interface IFilters {
  customer?: any;
  customer_id?: number;
  product?: any;
  product_id?: number;
  keyword?: string;
  status?: OrderStatus | any;
  formik_ordered_date: any;
  formik_expired_date: any;
  formik_last_payment_date: any;
  formik_next_payment_date: any;
  ordered_date?: any[];
  expired_date?: any[];
  last_payment_date?: any[];
  next_payment_date?: any[];
  per_page?: number;
  page: number;
}

interface ISorts {
  id?: string;
  phone_number?: string;
  last_payment_date?: string;
  next_payment_date?: string;
  expire_date?: string;
  ordered_date?: string;
  status?: string;
  total?: string;
}

export interface IOrderState {
  list: {
    data: IOrder[];
    pageOptions: IPageOptions;
    filters: IFilters;
    sorts: ISorts;
  };
  selected: IOrder | null;
}

const initialState: IOrderState = {
  list: {
    data: [],
    pageOptions: {
      currentPage: 0,
      perPage: 0,
      total: 0,
    },
    filters: {
      page: 1,
      customer: {},
      product: {},
      keyword: '',
      status: '',
      formik_ordered_date: {},
      formik_expired_date: {},
      formik_last_payment_date: {},
      formik_next_payment_date: {},
      per_page: DEFAULT_PER_PAGE,
    },
    sorts: {},
  },
  selected: null,
};

export const fetchAdminOrderList = createAsyncThunk(
  'admin/orders/fetchOrderList',
  async (_, { getState }) => {
    const { filters, sorts } = (getState() as any).admin.order.list;
    const payload = {
      ...filters,
      sort_field: sorts,
    };
    const {
      data: { data },
    } = await OrderApi.adminGetOrders(payload);
    return data;
  }
);

export const fetchAdminOrderCancel = createAsyncThunk(
  'admin/order/fetchOrderCancel',
  async (
    { order_id, last_coverage_date }: CancelOrderParams,
    { rejectWithValue }
  ) => {
    try {
      const {
        data: { data },
      } = await OrderApi.adminCancelOrder(order_id, last_coverage_date);
      return data.order;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const fetchAdminOrderDetail = createAsyncThunk(
  'admin/orders/fetchAdminOrderDetail',
  async (orderId: number) => {
    const {
      data: { data },
    } = await OrderApi.adminGetOrderDetail(orderId);
    return data;
  }
);

export const exportOrderList = createAsyncThunk(
  'admin/orders/exportOrderList',
  async (
    { exportFilter }: { exportFilter: IOrderExportFilter },
    { rejectWithValue }
  ) => {
    try {
      const { data: response } = await OrderApi.exportOrderList(exportFilter);
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'orders.csv');
      document.body.appendChild(link);
      link.click();
    } catch (e: any) {
      return rejectWithValue(e.response.data.messages[0]);
    }
  }
);

export const adminOrderSlice = createSlice({
  name: 'adminOrders',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<IFilters>) => {
      state.list.filters = action.payload;
    },
    setSorts: (state, action: PayloadAction<ISorts>) => {
      state.list.sorts = action.payload;
    },
  },
  extraReducers: {
    [fetchAdminOrderList.fulfilled as any]: (state, action) => {
      const { data, current_page, per_page, total } = action.payload;
      state.list.data = data;
      state.list.pageOptions = {
        currentPage: Number(current_page),
        perPage: Number(per_page),
        total,
      };
    },
    [fetchAdminOrderDetail.fulfilled as any]: (state, action) => {
      state.selected = action.payload;
    },
  },
});

export const { setFilter, setSorts } = adminOrderSlice.actions;

export default adminOrderSlice.reducer;
