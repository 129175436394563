import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import CheckoutApi from '../../apis/CheckoutApi';
import { productCheckoutTracking } from '../../utils/gtm/gtmDataLayer';
import { convertEncryptedId } from '../../utils/gtm/convertEncrypted';

interface ICheckoutInfo {
  [key: string]: any;
}

interface ICheckout2c2pInfo {
  payload: string | null;
  transaction_id: number;
  order_id: number;
  message: string;
}

interface IPaymentResult {
  transaction: {
    amount: string;
    format_price: string;
    duration: string;
    created_at: string;
    customer_id: number;
    id: number;
    order_id: number;
    payment_card_number: string;
    payment_date_time: string;
    payment_fail_reason: string;
    payment_invoice_number: string;
    payment_response_code: string;
    payment_response_status: string;
    status: string;
    subscription_id: number;
    type: string;
    updated_at: string;
  };
  message: string;
  error: boolean;
}

interface IFreeTrialCheckoutResult {
  message: string;
  error: boolean;
}

interface ICheckoutMarketingConsent {
  [key: string]: any;
}

export interface IServicesState {
  checkoutInfo: ICheckoutInfo | null;
  checkout2c2pInfo: ICheckout2c2pInfo | null;
  checkoutRetriesAvailable: number;
  paymentStatus: IPaymentResult | null;
  freeTrialCheckoutStatus: IFreeTrialCheckoutResult | null;
  errMess: string | null;
  checkoutMarketingConsent: ICheckoutMarketingConsent | null;
}

const MAX_RETRIES_AVAILABLE = 1;

export const initialState: IServicesState = {
  checkoutInfo: null,
  checkout2c2pInfo: null,
  checkoutRetriesAvailable: MAX_RETRIES_AVAILABLE,
  paymentStatus: null,
  freeTrialCheckoutStatus: null,
  errMess: null,
  checkoutMarketingConsent: null,
};

export const getCheckout2c2pInfo = createAsyncThunk(
  'checkout/getCheckout2c2pInfo',
  async ({ checkoutInfo }: any, { rejectWithValue }) => {
    try {
      const { data: response } = await CheckoutApi.postCheckoutProduct(
        checkoutInfo,
        false
      );
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const proceedCheckoutFreeTrial = createAsyncThunk(
  'checkout/proceedCheckoutFreeTrial',
  async ({ checkoutInfo }: any, { rejectWithValue }) => {
    try {
      const { data: response } = await CheckoutApi.postCheckoutProduct(
        checkoutInfo,
        true
      );
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const postCheckoutMarketingConsent = createAsyncThunk(
  'checkout/postCheckoutMarketingConsent',
  async ({ checkoutInfo }: any, { rejectWithValue }) => {
    try {
      const { data: response } = await CheckoutApi.postCheckoutMarketingConsent(
        checkoutInfo
      );
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const getPaymentStatus = createAsyncThunk(
  'checkout/getPaymentStatus',
  async (
    { customerInfo, currentPlan, paymentInfo, productInfo }: any,
    { rejectWithValue }
  ) => {
    try {
      const { data: response } = await CheckoutApi.postPaymentStatus(
        paymentInfo
      );
      return response.data;
    } catch (e: any) {
      /** Checkout Tracking */
      currentPlan &&
        productCheckoutTracking(
          await convertEncryptedId(customerInfo?.id),
          7,
          'Checkout failed',
          productInfo
        );
      /** End Checkout Tracking */
      return rejectWithValue(e.response.data);
    }
  }
);

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setCheckoutInfo: (state, action: PayloadAction<ICheckoutInfo | null>) => {
      state.checkoutInfo = action.payload;
    },
    setCheckout2c2pInfo: (
      state,
      action: PayloadAction<ICheckout2c2pInfo | null>
    ) => {
      state.checkout2c2pInfo = action.payload;
    },
    attemptRetry: (state) => {
      state.checkoutRetriesAvailable -= 1;
    },
    setPaymentStatus: (state, action: PayloadAction<IPaymentResult | null>) => {
      state.paymentStatus = action.payload;
    },
    checkoutMarketingConsent: (
      state,
      action: PayloadAction<ICheckoutMarketingConsent | null>
    ) => {
      state.checkoutMarketingConsent = action.payload;
    },
    setFreeTrialCheckoutStatus: (
      state,
      action: PayloadAction<IFreeTrialCheckoutResult | null>
    ) => {
      state.freeTrialCheckoutStatus = action.payload;
    },
    resetCheckout2c2pPayload: (state) => {
      if (state.checkout2c2pInfo) state.checkout2c2pInfo.payload = null;
    },
    resetErrMess: (state) => {
      state.errMess = initialState.errMess;
    },
    resetCheckoutState: (state) => {
      state.checkoutInfo = null;
      state.checkout2c2pInfo = null;
      state.checkoutRetriesAvailable = initialState.checkoutRetriesAvailable;
      state.paymentStatus = null;
      state.freeTrialCheckoutStatus = null;
      state.errMess = initialState.errMess;
    },
  },
  extraReducers: {
    [getCheckout2c2pInfo.fulfilled as any]: (state, action) => {
      state.checkout2c2pInfo = action.payload;
    },
    [getCheckout2c2pInfo.rejected as any]: (state, action) => {
      state.errMess = action.payload;
    },
    [getPaymentStatus.fulfilled as any]: (state, action) => {
      state.paymentStatus = action.payload;
    },
    [getPaymentStatus.rejected as any]: (state, action) => {
      state.paymentStatus = action.payload;
    },
    [proceedCheckoutFreeTrial.fulfilled as any]: (state, action) => {
      state.freeTrialCheckoutStatus = action.payload;
    },
    [proceedCheckoutFreeTrial.rejected as any]: (state, action) => {
      state.errMess = action.payload;
    },
    [postCheckoutMarketingConsent.fulfilled as any]: (state, action) => {
      state.checkoutMarketingConsent = action.payload;
    },
    [postCheckoutMarketingConsent.rejected as any]: (state, action) => {
      state.checkoutMarketingConsent = action.payload;
    },
  },
});

export const {
  setCheckoutInfo,
  setCheckout2c2pInfo,
  attemptRetry,
  setPaymentStatus,
  setFreeTrialCheckoutStatus,
  resetCheckout2c2pPayload,
  resetErrMess,
  resetCheckoutState,
  checkoutMarketingConsent,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
