import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ProductService from '../../apis/ProductApi';
import { IPlan } from './productsSlice';
import { IServiceInfo } from './servicesSlice';

export interface ICoverageAndConditionInfo {
  id: number;
  product_id: number;
  plan_id: number;
  type: string;
  heading_en: string;
  heading_th: string;
  element_en: {
    price: string;
    content: string;
  }[];
  element_th: {
    price: string;
    content: string;
  }[];
  created_at: string;
  updated_at: string;
}

export interface IProductDetailInfo {
  product: {
    id: number;
    name_en: string;
    name_th: string;
    short_desc_en: string;
    short_desc_th: string;
    description_en: string;
    description_th: string;
    image: string;
    created_at: string;
    updated_at: string;
    plans: IPlan;
  } | null;
  services: IServiceInfo[];
}

export interface IProductDetailState {
  coverageAndCondition: ICoverageAndConditionInfo[];
  detail: IProductDetailInfo;
  currentPlan: any | null;
}

export const fetchCoverageAndCondition = createAsyncThunk(
  'products/fetchCoverageAndCondition',
  async ({ productId, planId }: any) => {
    const { data: response } = await ProductService.getCoverageAndCondition(
      productId,
      planId
    );
    return response.data;
  }
);

export const fetchProductDetail = createAsyncThunk(
  'products/fetchProductDetail',
  async ({ productId, planId }: any) => {
    const { data: response } = await ProductService.getProductDetail(
      productId,
      planId
    );
    return response.data;
  }
);

export const fetchProductDetailWithoutCustomer = createAsyncThunk(
  'products/fetchProductDetailWithoutCustomer',
  async ({ productId, planId }: any) => {
    const { data: response } =
      await ProductService.getProductDetailWithoutCustomer(productId, planId);
    return response.data;
  }
);

export const initialState: IProductDetailState = {
  coverageAndCondition: [],
  detail: { product: null, services: [] },
  currentPlan: null,
};

export const productDetailSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setCoverageAndCondition: (
      state,
      action: PayloadAction<ICoverageAndConditionInfo[]>
    ) => {
      state.coverageAndCondition = action.payload;
    },
    setDetail: (state, action: PayloadAction<IProductDetailInfo>) => {
      state.detail = action.payload;
    },
    setCurrentPlan: (state, action: PayloadAction<IPlan | null>) => {
      state.currentPlan = action.payload;
    },
    resetCurrentPlan: (state) => {
      state.currentPlan = initialState.currentPlan;
    },
  },
  extraReducers: {
    [fetchCoverageAndCondition.fulfilled as any]: (state, action) => {
      state.coverageAndCondition = action.payload;
    },
    [fetchProductDetail.fulfilled as any]: (state, action) => {
      state.detail = action.payload;
    },
    [fetchProductDetailWithoutCustomer.fulfilled as any]: (state, action) => {
      state.detail = action.payload;
    },
  },
});

export const {
  setCoverageAndCondition,
  setDetail,
  setCurrentPlan,
  resetCurrentPlan,
} = productDetailSlice.actions;

export default productDetailSlice.reducer;
