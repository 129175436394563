import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import serviceApi from '../../apis/ServiceApi';

export interface IServiceInfo {
  id: number;
  name_en: string;
  name_th: string;
  short_desc_en: string;
  short_desc_th: string;
  description_en: string;
  description_th: string;
  created_at: string;
  updated_at: string;
  product_services: any[];
  quantity: number | null;
  service_status: number;
  image: string;
}

export interface IServicesState {
  services: IServiceInfo[];
}

const initialState: IServicesState = {
  services: [],
};

export const fetchServices = createAsyncThunk(
  'services/fetchServices',
  async () => {
    const {
      data: { data },
    } = await serviceApi.getServiceList();
    return data;
  }
);
export const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setServices: (state, action: PayloadAction<IServiceInfo[]>) => {
      state.services = action.payload;
    },
  },
  extraReducers: {
    [fetchServices.fulfilled as any]: (state, action) => {
      state.services = action.payload;
    },
  },
});

export const { setServices } = servicesSlice.actions;

export default servicesSlice.reducer;
