import { PrivacyPolicyEn, PrivacyPolicyPdpaConsentEN } from './privacyPolicy';
import { TermAndConditionsEN } from './termAndConditions';
import moment from 'moment';

export default {
  title: 'English title',
  locale: {
    en: 'English',
    th: 'Thailand',
  },
  menu: {
    home: 'Home',
    balance: 'Balance',
    careShop: 'Care Shop',
    profile: 'Profile',
  },
  common: {
    completeCare: 'COMPLETE CARE',
    error: 'Error',
    comingSoon: 'Coming soon',
    adminSection: 'Admin Section',
    logout: 'Logout',
    filter: 'Filter',
    reset: 'Reset',
    exportAsCsv: 'Export as CSV',
    male: 'Male',
    female: 'Female',
    rowsPerPage: 'Rows per page',
    of: 'of',
    startDate: 'Start Date',
    endDate: 'End Date',
    update: 'Update',
    cancel: 'Cancel',
    editSuccessfully: 'Edit successfully!',
    buyNow: 'BUY NOW',
    startFreeTrial: 'START FREE TRIAL',
    bookAnAppointment: 'Book an Appointment',
    oneMonth: '1 month',
    moreDetail: 'More Detail',
    next: 'Next',
    goBackToHomepage: 'GO BACK TO HOMEPAGE',
    confirm: 'CONFIRM',
    no: 'NO',
    bahtPerYear: 'THB',
    bahtPerMonth: 'THB/month',
    accept: 'Accept',
    decline: 'Decline',
    free: 'Free trial',
    freeLabel: 'Free!',
    expired: 'Expired',
    active: 'Active',
    productExpired: 'Product Expired',
  },
  page: {
    home: {
      products: 'Products',
      services: 'Services',
    },
    checkoutRejection: {
      refuseInsurance: 'Refuse Insurance',
      apologize: 'Apologize',
      description:
        'Nong Care is very sorry that you are not elligible for this product. You may visit our website rabbitcare.com to see more products and services. For more information, please contact our 24-hour hotline: 1438.',
    },
    thankYou: {
      dear: 'Dear {{name}},',
      thankYouText:
        'Thank you for choosing rabbit finance. Find below the summary and next steps for your policy underwriting:',
      thankYouTextFreeTrial:
        'Thank you, your 30 day free trial started. Find below the summary and next steps.',
      showCoverageDetail: 'Show coverage detail',
      nextSteps: 'Next steps',
      totalPrice: 'Total Price',
      beCovered: 'Be Covered',
      receivePolicy: 'Receive Policy',
      textIncludeTax: '*This price includes 7% VAT and stamp duty',
      goBackToHomepage: 'GO BACK TO HOMEPAGE',
      rabbitRewards: 'Rabbit Rewards',
      point: 'Points',
      showDetailOfRR: 'Show details of Rabbit Rewards',
      congratulations: 'Congratulations',
      receivePoint: `You will be able to automatically earn points after 15 days of the policy's free look period without cancellation during that period.`,
      insurancePolicy: 'When you receive your insurance policy via email.',
      checkPoint: 'Check your points here.',
      rabbitRewardLink: 'Click here to earn the Rabbit Rewards points',
    },
    serviceDetail: {
      time: 'time',
    },
    productDetail: {
      coverage: 'Coverage',
      condition: 'Condition',
    },
    healthQuestions: {
      healthQuestions: 'Health questions',
    },
    checkout: {
      checkout: 'Check out',
      personalInformation: 'Personal Information',
      title: 'Title',
      name: 'Name',
      surname: 'Surname',
      idCardNumber: 'ID Card Number',
      phoneNumber: 'Phone number',
      emailAddress: 'Email Address',
      dateOfBirth: 'Date of Birth',
      address: 'Address Details',
      addressLabel: 'Address',
      province: 'Province',
      district: 'District',
      subDistrict: 'Sub-district',
      postcode: 'Postcode',
      additionalQuestion: 'Additional Question',
      weightKg: 'Weight (kg)',
      heightCm: 'Height (Cm)',
      occupation: 'Occupation',
      subOccupation: 'Sub-occupation',
      confirm: 'CONFIRM',
      addCardDetails: 'Payment Details',
      selectCard: 'Select Card',
      newCard: 'New card',
      cardNo: 'Card No.',
      nameOfCardHolder: 'Name of card holder',
      expiryDate: 'Expiry Date',
      expiryDateYear: 'Expiry Date Year',
      cvv: 'CVV',
      saveCardDetails: 'Save card details',
      setToDefault: 'Set to default',
      shortDetails:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      informCustomer:
        '* This payment will be monthly recurring to automatically renew the policy 1 day before the expiry date.',
      checkoutForm: {
        selectPlaceholder: '--Select--',
      },
    },
    checkoutResult: {
      paymentUnsuccessful: 'Something went wrong',
      paymentUnsuccessfulSubtitle:
        'Sorry, your payment is not complete. Please try again, or contact Rabbit Care Call Center 1438',
      validCardUnsuccessful: 'Something went wrong',
      validCardUnsuccessfulSubtitle1: 'Invalid Card',
      validCardUnsuccessfulSubtitle2: 'Please try again',
      cancelLabel: 'Close',
      laterLabel: 'Later',
      retryLabel: 'Retry',
    },
    consent: {
      consent: 'Consent',
      rabbitRewardConsent: `Customers will earn Rabbit Rewards points if they give consent to Rabbit Rewards Link to T&C/ Privacy policy of RR`,
      warningText:
        'You will not receive points and other benefits from Rabbit Rewards.',
    },
    pdpaConsent: {
      checkbox1: {
        confirmLine1: `I consent to the collection, use, and/or disclosure of my sensitive data by Rabbit Care Broker Co., Ltd., and their affiliates, subsidiaries, and business partners for the following purposes:
        (i) <i>sensitive data from insurance related products or services including medical teleconsultation and telepharmacy services</i> (e.g., health data, disability) to register and enable you to use our insurance related products or services including medical teleconsultation and telepharmacy services; and
        (ii) <i>health data</i> to carry out financial transaction and service related to the payments (e.g., reimbursement), which we rely on consent basis as detailed in the`,
        condition1:
          'sensitive data from insurance related products or services including medical teleconsultation and telepharmacy services ',
        confirmLine2:
          '(e.g., health data, disability) to register and enable you to use our insurance related products or services including medical teleconsultation and telepharmacy services; and (ii) ',
        condition2: 'health data',
        confirmLine3:
          ' to carry out financial transaction and service related to the payments (e.g., reimbursement), which we rely on consent basis as detailed in the ',
      },
      checkBox2: {
        confirmLine1:
          'I consent to the collection, use, and/or disclosure of my Personal Data (e.g. name, surname and email) by Rabbit Care Broker Co., Ltd., and their affiliates, subsidiaries, and business partners for the following purposes: (i) marketing and communications; (ii) data analytics services; and (iii) for other businesses (such as digital marketing, banking and financial, and reward and loyalty programs, including their related products and services) which we rely on consent basis as detailed in the ',
      },
      privacyPolicy: 'Privacy Policy',
      termAndCondition: 'Term and Condition',
      confirmText1: 'You agree to the',
      confirmText2: 'of our services',
      acceptConsent: 'Yes, I accept',
      refuseConsent: 'No, I don’t accept',
    },
    lastCoverage: {
      lastCoverageDate: 'Last Coverage Date',
      description: 'Please provide your policy last coverage date?',
      placeHolderDateField: 'Please select your last coverage date',
    },
    confirmCancel: {
      confirmCancellation: 'Confirm Cancellation',
      description:
        'Please find below your refund amount calculated based on your last coverage date.',
    },
    cancellationSuccessful: {
      dear: 'Dear {{name}},',
      cancelText:
        'Your policy is cancelled. Please find your policy cancellation details below.',
    },
    currentCoverages: {
      pageTitle: 'Current Coverages',
      confirmPolicy:
        'By cancelling this policy, you will not get the coverages below.',
      confirmSection: 'Do you want to confirm cancellation?',
    },
    cancellationReasons: {
      title: 'Cancellation Reasons',
      reason: 'Why are your cancelling?',
      reasonDetail: 'Please provide the detail',
      placeHolderCombobox: 'Please tell us what happened',
      placeHolderTextField: 'Please Tell Us',
    },
    mySubscriptions: {
      yourPurchase: 'Your Purchase',
      mySubscriptions: 'My Subscriptions',
      myCancelledProducts: 'My Cancelled Products',
      product: 'Product',
      status: 'Status',
      nextPayment: 'Next Payment',
      total: 'Total',
      renew: 'RENEW',
      view: 'VIEW',
      rabbitRewards: 'Rabbit Reward',
      point: 'Points',
      at: 'at',
    },
    mySubscriptionDetail: {
      status: 'Status',
      policyNumber: 'Policy Number',
      PAPolicy: 'PA Policy',
      healthPolicy: 'Health Policy',
      startCoverageDate: 'Start Coverage Date',
      endCoverageDate: 'End Coverage Date',
      lastPaymentDate: 'Last Payment Date',
      nextPaymentDate: 'Next Payment Date',
      total: 'Total',
      refund: 'Refund Amount',
      cancel: 'CANCEL',
    },
    privacyPolicy: {
      title: 'Privacy Policy Rabbit Care',
      text: PrivacyPolicyEn,
    },
    privacyPolicyPdpaConsent: {
      title: 'นโยบายความเป็นส่วนตัว',
      text: PrivacyPolicyPdpaConsentEN,
    },
    termAndConditions: {
      title: 'Term and Conditions',
      text: TermAndConditionsEN,
    },
    error: {
      oops: 'Something went wrong',
      somethingWentWrong:
        'Sorry, there was an unexpected error. Please, try again.',
      goBack: 'Back to main page',
    },
    refundError: {
      oops: 'Something went wrong',
      somethingWentWrong:
        'Nong Care is very sorry that your policy cannot be cancelled as it is not met the insurer’s conditions. For more information, please contact our 24-hour hotline: 1438.',
      goBack: 'Back to main page',
    },
  },
  admin: {
    product: {
      productManagement: 'Product Management',
      id: 'ID',
      productId: 'Product ID',
      image: 'Image',
      name_en: 'English Name',
      name_th: 'Thai Name',
      short_desc_en: 'English Short Description',
      short_desc_th: 'Thai Short Description',
      description_en: 'English Description',
      description_th: 'Thai Description',
      status: 'Status',
      action: 'Action',
      productNotFound: 'Product Not Found',
      saveChanges: 'Save Changes',
      backToList: 'Back To List',
      consentDetails: 'Consent Details',
      english: 'English',
      thai: 'Thai',
      number: 'No.',
      title: 'Title',
      content: 'Content',
      option: 'Option {{option_no}}',
      warningText: 'Warning text',
      orderPosition: 'Position',
    },
    service: {
      serviceManagement: 'Service Management',
      id: 'ID',
      serviceId: 'Service ID',
      image: 'Image',
      name_en: 'English Name',
      name_th: 'Thai Name',
      short_desc_en: 'English Short Description',
      short_desc_th: 'Thai Short Description',
      description_en: 'English Description',
      description_th: 'Thai Description',
      status: 'Status',
      action: 'Action',
      serviceNotFound: 'Service Not Found',
      saveChanges: 'Save Changes',
      backToList: 'Back To List',
      products: 'Products',
      delete: 'Delete',
      selectProduct: 'Select product',
      add: 'Add',
    },
    customer: {
      customer: 'Customer',
      customerManagement: 'Customer Management',
      keyword: 'Keyword',
      keywordPlaceholder: 'Search by name, email, phone, ID card number',
      purchasedProduct: 'Purchased Product',
      selectProducts: 'Select products',
      lastPurchaseDate: 'Last Purchase Date',
      createDate: 'Create Date',
      updateDate: 'Update Date',
      id: 'ID',
      customerId: 'Customer ID',
      title: 'Title',
      name: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      gender: 'Gender',
      dateOfBirth: 'Date of Birth',
      nationality: 'Nationality',
      mobileNumber: 'Mobile Number',
      maritalStatus: 'Marital Status',
      weight: 'Weight',
      height: 'Height',
      occupation: 'Occupation',
      subOccupation: 'Sub Occupation',
      action: 'Action',
      customerNotFound: 'Customer Not Found',
      idCard: 'ID Card',
      passport: 'Passport',
      landlineNumber: 'Landline Number',
      language: 'Language',
      backToList: 'Back To List',
      orderDetails: 'Order Details',
    },
    order: {
      orderManagement: 'Order Management',
      keyword: 'Keyword',
      keywordPlaceholder: 'Search by name, email, phone, ID card number',
      selectProducts: 'Select products',
      selectCustomers: 'Select customers',
      orderDetails: 'Order Details',
      id: 'ID',
      orderId: 'Order ID',
      status: 'Status',
      product: 'Product',
      price: 'Price',
      expireDate: 'Policy Expiration Date',
      purchasedProductPlan: 'Purchased Product Plan',
      orderDate: 'Order Date',
      activeDate: 'Active Date',
      lastCoverageDate: 'Last Coverage Date',
      lastPaymentDate: 'Last Payment Date',
      nextPaymentDate: 'Next Payment Date',
      policyId: 'Policy ID',
      PAPolicy: 'PA Policy',
      healthPolicy: 'Health Policy',
      policyNumber: 'Policy Number',
      action: 'Action',
      noOrders: 'No Orders',
      backToList: 'Back To List',
      name_en: 'English Name',
      name_th: 'Thai Name',
      paymentAmount: 'Payment Amount',
      orderAddress: 'Order Address',
      address: 'Address',
      province: 'Province',
      district: 'District',
      subdistrict: 'Subdistrict',
      postcode: 'Postcode',
      email: 'Email',
      mobileNumber: 'Mobile Number',
      txid: 'Transaction ID',
      invoiceNumber: 'Invoice number',
      sureMoveStatusToCancel:
        'Are you sure you want to change status to cancel ?',
      cancelOrder: 'Cancel order',
    },
    thankYou: {
      thankYouPage: 'Thank You Page',
      thankYouPageDetails: 'Thank You Page Details',
      id: 'ID',
      type: 'Type',
      content_en: 'Content (English)',
      content_th: 'Content (Thai)',
      receivePolicy: 'Receive Policy',
      beCovered: 'Be Covered',
      nextStepContent_en: '{{nextStep}} Content (English)',
      nextStepContent_th: '{{nextStep}} Content (Thai)',
      action: 'Action',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      backToList: 'Back To List',
    },
  },
  errors: {
    required: '{{field}} is required',
  },
  footer: {
    companyName: 'Rabbit Care Broker Co., Ltd.',
    lifeBrokerID: 'Life insurance broker ID ช000011/2559',
    NonLifeBrokerID: 'Non-Life broker ID ว00021/2557',
    copyright: `Copyright ${moment().format('YYYY')}`,
    groupName: 'Rabbit Care Broker Co., Ltd. (a BTS Group Company)',
  },
};
