import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { logout } from '../../redux/slices/admin/adminAuthSlice';
import LanguageSelector from '../LanguageSelector';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { setAdminLocale } from '../../redux/slices/globalSlice';

type AdminHeaderProps = {
  drawerWidth: number;
  open: boolean;
  toggleDrawer: () => void;
};

const AdminHeader = ({ open, drawerWidth, toggleDrawer }: AdminHeaderProps) => {
  const useStyles = makeStyles((theme) => ({
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
  }));

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    locale: { admin },
  } = useAppSelector((state) => state.global);

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          data-testid="menu-icon-btn"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {`Rabbit Care Shop - ${t('common.adminSection')}`}
        </Typography>
        <Box mr={3}>
          <LanguageSelector
            data-testid="lang-selector"
            value={admin}
            onChange={(event: React.ChangeEvent<any>) => {
              dispatch(setAdminLocale(event.target.value));
            }}
          />
        </Box>
        <IconButton
          data-testid="exit-icon-btn"
          size="small"
          color="inherit"
          onClick={() => dispatch(logout())}
        >
          <ExitToAppIcon style={{ marginRight: '10px' }} />
          {t('common.logout')}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(AdminHeader);
