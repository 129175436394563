import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import AuthApi from '../../apis/AuthApi';

interface CustomerCardInfo {
  id: number;
  customer_id: number;
  is_default: boolean;
  is_saved: boolean;
  masked_card_info: string;
  card_holder: string;
  encryptedCardInfo: string;
  expMonthCardInfo: string;
  expYearCardInfo: string;
}

interface CustomerAddress {
  id: number;
  customer_id: number;
  address_line_1: string;
  address_line_2: string;
  house_no: string;
  moo_no: null;
  moo: null;
  building: null;
  floor_no: null;
  room_no: null;
  trok: null;
  soi: null;
  road: null;
  sub_district_th: string;
  sub_district_code: string;
  sub_district_en: string;
  district_th: string;
  district_code: string;
  district_en: string;
  province_th: string;
  province_code: string;
  province_en: string;
  postcode: null;
  type: string;
}

interface CustomerLastOrder {
  id: number;
  customer_id: number;
  product_id: number;
  plan_price_id: number;
  card_info_id: number;
  subTotal: number;
  total: string;
  grandTotal: number;
  status: string;
  ordered_date: string;
  active_date: string;
  expired_date: string;
  title: string;
  phone_number: string;
  email: string;
  address: string;
  province_code: string;
  district_code: string;
  sub_district_code: string;
  postcode: string;
  weight: number | null;
  height: number | null;
  occupation_code: string;
  sub_occupation_code: string;
  created_at: string;
  updated_at: string;
  last_coverage_date: string;
  next_payment_date: string;
  last_payment_date: string;
  txid: string;
  e_policy: string;
  province: string;
  district: string;
  sub_district: string;
  policy_id: string;
  prem_amount: null;
  totprem_amount: null;
  vat_amount: null;
  stamp_amount: null;
  endorse_no: null;
  format_total_price: string;
  format_grand_total_price: string;
  format_sub_total_price: string;
}

interface CustomerInfo {
  id: number;
  title: string;
  first_name: string;
  last_name: string;
  email: string;
  gender: number;
  date_of_birth: string;
  nationality: string;
  occupation_th: string;
  occupation_code: string;
  occupation_en: string;
  sub_occupation_th: string;
  sub_occupation_code: string;
  sub_occupation_en: string;
  mobile_number: string;
  language: string;
  landline_number: number;
  weight: number | null;
  height: number | null;
  marital_status: number | null;
  card_type: number;
  ID_card_or_passport: string;
  card_info: CustomerCardInfo[];
  address: CustomerAddress;
  last_order: CustomerLastOrder;
  is_rabbit_reward_consent: boolean;
}

export interface IAuthState {
  token: string | null;
  customerInfo: CustomerInfo | null;
  isNewCustomer: Array<any>;
}

const initialState: IAuthState = {
  token: null,
  customerInfo: null,
  isNewCustomer: [],
};

export const getCustomerInfo = createAsyncThunk(
  'auth/getCustomerInfo',
  async () => {
    const { data: response } = await AuthApi.getCustomerInfo();
    return response.data[0];
  }
);

export const checkNewCustomer = createAsyncThunk(
  'auth/checkNewCustomer',
  async () => {
    const { data: response } = await AuthApi.checkNewCustomer();
    return response.data;
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
  extraReducers: {
    [getCustomerInfo.fulfilled as any]: (state, action) => {
      state.customerInfo = action.payload;
    },
    [checkNewCustomer.fulfilled as any]: (state, action) => {
      state.isNewCustomer = action.payload;
    },
  },
});

export const { setToken } = authSlice.actions;

export default authSlice.reducer;
