import { Locale } from '../constants';

export const convertCurrencyNumber = (numberStr: string, locale: Locale) => {
  try {
    const [value, unit] = numberStr.split(' ');
    const valueNumber = Number(value);
    if (!valueNumber || !unit) return numberStr;
    switch (locale) {
      case Locale.ENG:
        return `${valueNumber.toLocaleString('en-US')} ${unit}`;
      case Locale.THAI:
        return `${valueNumber.toLocaleString('th-TH')} ${unit}`;
      default:
        return `${valueNumber.toLocaleString('en-US')} ${unit}`;
    }
  } catch (e: any) {
    return numberStr;
  }
};

export const convertPriceStringToNumber = (numberStr: string) => {
  try {
    return Number(numberStr.replace(/,/g, ''));
  } catch (e: any) {
    return numberStr;
  }
};
