import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IServiceInfo } from '../servicesSlice';
import ServiceApi from '../../../apis/ServiceApi';

export interface IServicesState {
  services: IServiceInfo[];
  selected: IServiceInfo | null;
}

const initialState: IServicesState = {
  services: [],
  selected: null,
};

export const fetchAdminServices = createAsyncThunk(
  'admin/services/fetchServices',
  async () => {
    const {
      data: { data },
    } = await ServiceApi.getAdminServiceList();
    return data;
  }
);

export const fetchAdminServiceDetail = createAsyncThunk(
  'admin/services/fetchServiceDetail',
  async (id: number) => {
    const {
      data: { data },
    } = await ServiceApi.getAdminServiceDetail(id);
    return data;
  }
);

export const updateAdminService = createAsyncThunk(
  'admin/services/updateAdminservice',
  async (service: any, { dispatch, getState }) => {
    await ServiceApi.updateAdminService(service);
    dispatch(
      fetchAdminServiceDetail((getState() as any).admin.services.selected.id)
    );
  }
);

export const deleteProductService = createAsyncThunk(
  'admin/services/deleteProductService',
  async (id: number, { dispatch, getState }) => {
    await ServiceApi.deleteProductService(id);
    dispatch(
      fetchAdminServiceDetail((getState() as any).admin.services.selected.id)
    );
  }
);

export const adminServiceSlice = createSlice({
  name: 'adminServices',
  initialState,
  reducers: {
    setAdminServices: (state, action: PayloadAction<IServiceInfo[]>) => {
      state.services = action.payload;
    },
  },
  extraReducers: {
    [fetchAdminServices.fulfilled as any]: (state, action) => {
      state.services = action.payload;
    },
    [fetchAdminServiceDetail.fulfilled as any]: (state, action) => {
      state.selected = action.payload;
    },
  },
});

export const { setAdminServices } = adminServiceSlice.actions;

export default adminServiceSlice.reducer;
