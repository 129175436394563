import httpClient from '../utils/axios';

const RABBIT_REWARD_ENDPOINT_SUFFIX = '/api/rabbit';

const RabbitRewardApi = {
  syncRRMember: () =>
    httpClient.post(`${RABBIT_REWARD_ENDPOINT_SUFFIX}/sync-rr-member`),
  checkRRmember: () =>
    httpClient.post(`${RABBIT_REWARD_ENDPOINT_SUFFIX}/check-rr-member`),
};

export default RabbitRewardApi;
