import httpClient from '../utils/axios';

const SERVICE_ENDPOINT_SUFFIX = '/api/service';
const ADMIN_SERVICE_ENDPOINT_SUFFIX = '/api/admin/service';
const ADMIN_PRODUCT_SERVICE_SUFFIX = 'api/admin/product-service';

const ServiceApi = {
  getServiceList: () => httpClient.get(SERVICE_ENDPOINT_SUFFIX),
  getServiceDetail: (id: string) =>
    httpClient.get(`${SERVICE_ENDPOINT_SUFFIX}/${id}`),
  callServiceRequest: (url: string, data?: any) => httpClient.post(url, data),
  bookServiceRequest: (customerServiceId: number) =>
    httpClient.post(`${SERVICE_ENDPOINT_SUFFIX}/book/${customerServiceId}`),

  getAdminServiceList: () =>
    httpClient.get(`${ADMIN_SERVICE_ENDPOINT_SUFFIX}/list`),
  getAdminServiceDetail: (id: number) =>
    httpClient.get(`${ADMIN_SERVICE_ENDPOINT_SUFFIX}/detail/${id}`),
  updateAdminService: (service: any) =>
    httpClient.post(`${ADMIN_SERVICE_ENDPOINT_SUFFIX}/${service.id}`, service),
  deleteProductService: (id: number) =>
    httpClient.delete(`${ADMIN_PRODUCT_SERVICE_SUFFIX}/${id}`),
};

export default ServiceApi;
