import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import AdminAuthApi from '../../../apis/admin/AdminAuthApi';
import httpClient from '../../../utils/axios';

export enum AdminRoles {
  ADMIN = 1,
}

export interface IAuthState {
  token: string | null;
  errMess: string | null;
  adminRole: AdminRoles | null;
}

export interface LoginDetails {
  username: string;
  password: string;
}

interface LoginRequestProps {
  loginDetails: LoginDetails;
  callback: () => void;
}

export const adminLogin = createAsyncThunk(
  'auth/adminLogin',
  async (
    { loginDetails, callback }: LoginRequestProps,
    { rejectWithValue }
  ) => {
    try {
      const { data: response } = await AdminAuthApi.adminLogin(loginDetails);
      const token = response.data.token;
      httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return token;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    } finally {
      callback();
    }
  }
);

export const getAdminRole = createAsyncThunk(
  'auth/getAdminRole',
  async ({ token }: { token: string }) => {
    const { data: response } = await AdminAuthApi.getAdminRole(token);
    return response.data;
  }
);

const initialState: IAuthState = {
  token: null,
  errMess: null,
  adminRole: null,
};

export const adminAuthSlice = createSlice({
  name: 'adminAuth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    setErrMess: (state, action: PayloadAction<string | null>) => {
      state.errMess = action.payload;
    },
    logout: (state) => {
      state.token = initialState.token;
      state.adminRole = initialState.adminRole;
    },
    resetErrMess: (state) => {
      state.errMess = initialState.errMess;
    },
  },
  extraReducers: {
    [adminLogin.fulfilled as any]: (state, action) => {
      state.token = action.payload;
    },
    [adminLogin.rejected as any]: (state, action) => {
      state.errMess = action.payload;
    },
    [getAdminRole.fulfilled as any]: (state, action) => {
      state.adminRole = action.payload;
    },
  },
});

export const { setToken, setErrMess, logout, resetErrMess } =
  adminAuthSlice.actions;

export default adminAuthSlice.reducer;
