import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Locale, DEFAULT_LOCALE } from '../../constants';

export interface IThemeState {
  theme: number;
  locale: { user: Locale; admin: Locale };
  error: any | null;
}

const initialState: IThemeState = {
  theme: 0,
  locale: {
    user: (DEFAULT_LOCALE as Locale) || Locale.THAI,
    admin: (DEFAULT_LOCALE as Locale) || Locale.THAI,
  },
  error: null,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<number>) => {
      state.theme = action.payload;
    },
    setUserLocale: (state, action: PayloadAction<Locale>) => {
      state.locale.user = action.payload;
    },
    setAdminLocale: (state, action: PayloadAction<Locale>) => {
      state.locale.admin = action.payload;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
  },
});

export const { setTheme, setUserLocale, setAdminLocale, setError } =
  globalSlice.actions;

export default globalSlice.reducer;
