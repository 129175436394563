import { getSnippet, NewRelicConfig } from './Snippets';
import {
  NEW_RELIC_ACC_ID,
  NEW_RELIC_LIC_KEY,
  NEW_RELIC_APP_ID,
} from '../../constants';

class NewRelicManager {
  static getNewRelicScript(args: NewRelicConfig) {
    const snippet = getSnippet(args);

    const script = document.createElement('script');
    script.async = true;
    script.innerHTML = snippet;
    return script;
  }

  static initialize() {
    const newRelicScript = NewRelicManager.getNewRelicScript({
      accountID: NEW_RELIC_ACC_ID || '',
      licenseKey: NEW_RELIC_LIC_KEY || '',
      applicationID: NEW_RELIC_APP_ID || '',
    });
    document.head.insertBefore(newRelicScript, document.head.childNodes[0]);
  }
}

export default NewRelicManager;
