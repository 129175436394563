import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import authReducer from '../slices/authSlice';
import globalReducer from '../slices/globalSlice';
import subscriptionReducer from '../slices/subscriptionSlice';
import servicesReducer from '../slices/servicesSlice';
import productsReducer from '../slices/productsSlice';
import consentReducer from '../slices/consentSlice';
import questionReducer from '../slices/questionSlice';
import serviceDetailReducer from '../slices/serviceDetailSlice';
import productDetailReducer from '../slices/productDetailSlice';
import checkoutReducer from '../slices/checkoutSlice';
import orderReducer from '../slices/orderSlice';
import pageReducer from '../slices/pageSlice';

import adminAuthReducer from '../slices/admin/adminAuthSlice';
import adminServicesReducer from '../slices/admin/adminServicesSlice';
import adminProductsReducer from '../slices/admin/adminProductsSlice';
import adminThankYouPageReducer from '../slices/admin/adminThankYouPageSlice';
import adminCustomerReducer from '../slices/admin/adminCustomerSlice';
import adminOrderReducer from '../slices/admin/adminOrderSlice';
import adminConsentReducer from '../slices/admin/adminConsentSlice';

import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { NODE_ENV } from '../../constants';

const rootReducer = combineReducers({
  auth: authReducer,
  global: globalReducer,
  subscription: subscriptionReducer,
  services: servicesReducer,
  products: productsReducer,
  consent: consentReducer,
  questions: questionReducer,
  order: orderReducer,
  serviceDetail: serviceDetailReducer,
  productDetail: productDetailReducer,
  checkout: checkoutReducer,
  pages: pageReducer,
  adminAuth: adminAuthReducer,
  admin: combineReducers({
    services: adminServicesReducer,
    products: adminProductsReducer,
    adminThankYouPage: adminThankYouPageReducer,
    customer: adminCustomerReducer,
    order: adminOrderReducer,
    consent: adminConsentReducer,
  }),
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'global',
    'auth',
    'products',
    'productDetail',
    'checkout',
    'adminAuth',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
