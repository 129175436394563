import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { useHistory, useLocation } from 'react-router-dom';
import { Routes } from '../../constants';
import { useTranslation } from 'react-i18next';

interface INavItemProps {
  icon: any;
  label: string;
  to: string;
}

const NavItem = ({ icon, label, to }: INavItemProps) => {
  const history = useHistory();
  const location = useLocation();

  const redirect = () => {
    history.push(to);
  };

  return (
    <ListItem
      data-testid="nav-item"
      button
      selected={location.pathname.includes(to)}
      key={label}
      onClick={redirect}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
};

export const MainListItems = () => {
  const { t } = useTranslation();

  const listItems: INavItemProps[] = [
    {
      icon: <DashboardIcon />,
      label: t('admin.product.productManagement'),
      to: Routes.ADMIN_PRODUCT,
    },
    {
      icon: <DashboardIcon />,
      label: t('admin.service.serviceManagement'),
      to: Routes.ADMIN_SERVICE,
    },
    {
      icon: <DashboardIcon />,
      label: t('admin.customer.customerManagement'),
      to: Routes.ADMIN_CUSTOMER,
    },
    {
      icon: <DashboardIcon />,
      label: t('admin.order.orderManagement'),
      to: Routes.ADMIN_ORDER,
    },
    {
      icon: <DashboardIcon />,
      label: t('admin.thankYou.thankYouPage'),
      to: Routes.ADMIN_THANK_YOU_PAGE,
    },
  ];
  return (
    <>
      {listItems.map(({ icon, label, to }: INavItemProps) => (
        <NavItem key={label} icon={icon} label={label} to={to} />
      ))}
    </>
  );
};
