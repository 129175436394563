import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withWidth } from '@material-ui/core';
import styled from 'styled-components';
import Header from '../components/Header';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  background-color: #eff2f7;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 84px;
`;

export interface IAppLayoutProps {
  children?: any;
  width?: any;
  [key: string]: any;
}

const AppLayout = ({ children }: IAppLayoutProps) => {
  return (
    <Root>
      <CssBaseline />
      <Header />
      <Content>{children}</Content>
    </Root>
  );
};

export default withWidth()(AppLayout);
