const shadows = [
  'none',
  '0 -5px 6px #1067cc1a',
  '0 3px 6px #0000000d',
  '0 3px 10px #0000000d',
  '5px 10px 18px #a8d2ff4d',
  '-5px 5px 10px #a8d2ff4d',
  '0 0 10px #0000001a',
];

export default shadows;
