import httpClient from '../utils/axios';

const CUSTOMER_ENDPOINT_SUFFIX = '/api/admin/customer';

export const DEFAULT_PER_PAGE = 10;

export interface ICustomerFilter {
  keyword?: string;
  purchased_product?: number[];
  last_purchase_date?: string;
  create_update_date?: string;
  per_page?: number;
  page: number;
}

export interface ICustomerExportFilter {
  keyword?: string;
  purchased_product?: number[];
  last_purchase_date?: string;
  create_update_date?: string;
}

const CustomerApi = {
  getAdminCustomerList: (
    params: ICustomerFilter = { page: 1, per_page: DEFAULT_PER_PAGE }
  ) => httpClient.get(`${CUSTOMER_ENDPOINT_SUFFIX}/list-customer`, { params }),
  getAllAdminCustomers: () =>
    httpClient.get(`${CUSTOMER_ENDPOINT_SUFFIX}/list-customer`),
  exportCustomerList: (params: ICustomerExportFilter) => {
    return httpClient.post(`${CUSTOMER_ENDPOINT_SUFFIX}/export`, null, {
      params,
      responseType: 'blob',
    });
  },
};

export default CustomerApi;
